import React, { useEffect, useState, forwardRef } from 'react';
import { QrReader } from "react-qr-reader";
import jwt from "jsonwebtoken";
import { SERVER } from '../../config';
import useAxios from 'axios-hooks';
import { CircularProgress, Grid, IconButton, Tooltip, Typography, Dialog, Slide, List, ListItem, ListItemText, Button, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Swal from "sweetalert2";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {
  ArrowBack as ArrowBackIcon,
  LocationOn as LocationOnIcon,
  LocalHospital as LocalHospitalIcon,
  Check as CheckIcon
} from "@material-ui/icons";
import Logo from "../../assets/images/Logo.png";
import moment from 'moment';
import PDFView from '../componentsHelpers/PDFView';
import { pdf } from '@react-pdf/renderer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CodeReader() {
  const [goToLogin, setGoToLogin] = useState(false);
  const [openPatientInfoDialog, setOpenPatientInfoDialog] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [patientPicture, setPatientPicture] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const [openHospitalsDialog, setOpenHospitalsDialog] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [
    {
      data: getHospitalsData,
      loading: getHospitalsLoading,
      error: getHospitalsError,
    },
    executegetHospitals,
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: registerVisitData,
      loading: registerVisitLoading,
      error: registerVisitError,
    },
    executeRegisterVisit,
  ] = useAxios(
    {
      url: `${SERVER}/codeReader/inHospital/registerVisit`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: sendMessageData,
      error: sendMessageError,
    },
    executeSendMessage,
  ] = useAxios(
    {
      url: `${SERVER}/codeReader/inHospital/sendMessage`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    localStorage.removeItem('isSuccessCode');
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if(!isOnline) {
      localStorage.removeItem('isSuccessCode');
    }
  }, [isOnline])

  useEffect(() => {
    if (getHospitalsData) {
      setHospitals(getHospitalsData);
      const idHospital = getIdHospital();

      if (idHospital > 0) {
        const searchHospital = getHospitalsData.find(hospital => hospital.id === idHospital);
        setSelectedHospital(searchHospital);
      }
      else {
        handleClickOpenHospitalsDialog();
      }
    }

    if (getHospitalsError) {
      Swal.fire({
        icon: "error",
        title: "An error occurred on the server",
        showConfirmButton: true,
      });
      localStorage.removeItem('isSuccessCode');
      return;
    }
  }, [getHospitalsData, getHospitalsError]);

  useEffect(() => {

    async function sendMessage(data) {
      const blobPDF = await pdf(<PDFView data={data} />).toBlob();
      const formData = new FormData();
      formData.append("idHospital", registerVisitData.idHospital);
      formData.append("firstNamePatient", registerVisitData.patientData.firstName);
      formData.append("lastNamePatient", registerVisitData.patientData.lastName);
      formData.append("namePDF", `(${registerVisitData.patientData.id})${registerVisitData.patientData.firstName} ${registerVisitData.patientData.lastName} info.pdf`);
      formData.append("pathPDF", blobPDF);
      executeSendMessage({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }

    if (!registerVisitData) {
      return;
    }

    if (registerVisitData.error) {
      Swal.fire({
        icon: "error",
        title: registerVisitData.message,
        showConfirmButton: true,
      });
      localStorage.removeItem('isSuccessCode');
      return;
    }

    if (registerVisitError) {
      Swal.fire({
        icon: "error",
        title: "An error occurred on the server",
        showConfirmButton: true,
      });
      localStorage.removeItem('isSuccessCode');
      return;
    }

    setPatientName(`${registerVisitData.patientData.firstName} ${registerVisitData.patientData.lastName}`);
    setPatientPicture(registerVisitData.patientData.picture);
    handleClickOpenPatientInfoDialog();

    sendMessage(registerVisitData.patientData);

    setTimeout(() => {
      handleClosePatientInfoDialog();
    }, 5000);

  }, [registerVisitData, registerVisitError, executeSendMessage]);

  useEffect(() => {
    if (sendMessageError) {
      Swal.fire({
        icon: "error",
        title: "An error occurred on the server",
        showConfirmButton: true,
      });
      localStorage.removeItem('isSuccessCode');
      return;
    }

  }, [sendMessageData, sendMessageError]);

  if (registerVisitLoading || getHospitalsLoading) {
    return <div style={{ width: '100%', height: 'calc(100vh - 16px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src={Logo} alt="logo" height={200} />
      <CircularProgress />
    </div>;
  }

  const onGoToLogin = () => {
    setGoToLogin(true);
  }

  const handleClickOpenPatientInfoDialog = () => {
    setOpenPatientInfoDialog(true);
  };

  const handleClosePatientInfoDialog = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenPatientInfoDialog(false);
    localStorage.removeItem('isSuccessCode');
  };

  const handleClickOpenHospitalsDialog = () => {
    setOpenHospitalsDialog(true);
  };

  const handleCloseHospitalsDialog = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenHospitalsDialog(false);
  };

  const getIdHospital = () => {
    try {
      const idHospitalEncode = localStorage.getItem('hospital');
      const idHospital = parseInt(atob(idHospitalEncode));
      return idHospital;
    }
    catch (err) {
      return 0;
    }
  }

  const onSuccessCode = (url) => {
    try {
      const idHospital = getIdHospital();

      if (idHospital === 0) {
        Swal.fire({
          icon: "error",
          title: "Hospital parameter missing",
          showConfirmButton: true,
        });
        localStorage.removeItem('isSuccessCode');
        return;
      }

      const token = url.split("/").pop();
      const decodedToken = jwt.verify(token, "mysecretpassword");
      const idPatient = decodedToken.data.idPatient;
      const date = moment().format("YYYY-MM-DD");
      const hour = moment().format("H:mm");
      executeRegisterVisit({
        data: {
          idPatient,
          idHospital,
          date,
          hour
        }
      });
      setQrCode('');
    }
    catch (err) {
      localStorage.removeItem('isSuccessCode');
      setQrCode('Invalid QR Code');
    }
  }

  return goToLogin ? (<Redirect to="/login" />)
    : (
      <Grid container style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <Tooltip title="Back">
              <IconButton onClick={onGoToLogin}>
                <ArrowBackIcon style={{ color: "#0274ca" }} />
              </IconButton>
            </Tooltip>
            Patient´s QR Code Scanning
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontWeight: '700' }} onClick={handleClickOpenHospitalsDialog}>
            <Tooltip title="Location">
              <IconButton >
                <LocationOnIcon style={{ color: "#000000" }} />
              </IconButton>
            </Tooltip>
            {selectedHospital ? selectedHospital.name : 'No hospital selected'}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img src={Logo} alt="logo" height={200} />
        </Grid>
        <Grid item xs={12} style={{ height: '400px'}}>
          {isOnline ? (<QrReader
            onResult={(result, error) => {
              if (localStorage.getItem('isSuccessCode')) {
                return;
              }

              if (!localStorage.getItem('hospital')) {
                setSelectedHospital(null);
                handleClickOpenHospitalsDialog();
                return;
              }

              if (!!result) {
                localStorage.setItem('isSuccessCode', 'true');
                onSuccessCode(result?.text);
              }

              if (!!error) {
                /* console.info(error); */
              }
            }}
            videoStyle={{ height: '400px' }}
          />)
        : (
          <div style={{width: '100%', height: '400px', display: 'flex', justifyContent: 'center'}}>
            <div style={{backgroundColor: 'black', height: '100%', width: '550px'}}>
              <Typography variant='h2' style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Check the internet</Typography>
            </div>
          </div>
        )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' color='error' style={{ color: isOnline ? 'green' : '#f44336', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '5px', width: '15px', height: '15px', borderRadius: '50%', backgroundColor: isOnline ? 'green' : '#f44336' }}></div>{isOnline ? 'Online' : 'Offline'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='error'>{qrCode !== '' ? qrCode : ''}</Typography>
        </Grid>
        <Dialog
          disableEscapeKeyDown
          open={openPatientInfoDialog}
          onClose={handleClosePatientInfoDialog}
          TransitionComponent={Transition}
        >
          <Grid container style={{ textAlign: 'center', padding: '48px' }}>
            <Grid item xs={12}>
              <img
                src={`${SERVER}/files/images/${patientPicture}`}
                alt={patientPicture}
                height="200"
                width="200"
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Typography variant='h6'>Hello {patientName}</Typography>
            </Grid>
          </Grid>
        </Dialog>
        <Dialog
          disableEscapeKeyDown
          open={openHospitalsDialog}
          onClose={handleCloseHospitalsDialog}
          TransitionComponent={Transition}
        >
          <DialogTitle >
            Select the hospital where the scanner is located
          </DialogTitle >
          <DialogContent>
            <List>
              {hospitals.length > 0 ? hospitals.map((hospital, index) => (
                <ListItem key={index} button onClick={() => {
                  const encodeId = btoa(hospital.id);
                  localStorage.setItem('hospital', encodeId);
                  setSelectedHospital(hospital);
                  handleCloseHospitalsDialog();
                }}>
                  <LocalHospitalIcon style={{ marginRight: '15px' }} />
                  <ListItemText
                    primary={hospital.name}
                  />
                  {hospital.id === selectedHospital?.id && (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </ListItem>
              )) : (
                <ListItem button onClick={() => {
                  executegetHospitals();
                }}>
                  <ListItemText
                    primary='If hospitals are not showing, click here to reload the hospital´s list.'
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
          <DialogActions >
            <Button variant="contained" color="secondary" onClick={handleCloseHospitalsDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
}
