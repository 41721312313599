import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Done as DoneIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import { keyValidation, pasteValidation } from "../../helpers/inputHelpers";
import useAxios from "axios-hooks";
import { SERVER } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";

export default function EmergencyCodes(props) {
  const userData = props.userData;
  const [emergencyCodes, setEmergencyCodes] = useState([]);
  const [codeName, setCodeName] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [seePatientInformation, setSeePatientInformation] = useState("1");

  const [
    {
      data: getEmergencyCodesData,
      loading: getEmergencyCodesLoading,
      error: getEmergencyCodesError,
    },
    executeGetEmergencyCodes,
  ] = useAxios(
    {
      url: `${SERVER}/emergencyCodes/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: generateEmergencyCodeData,
      loading: generateEmergencyCodeLoading,
      error: generateEmergencyCodeError,
    },
    executeGenerateEmergencyCode,
  ] = useAxios(
    {
      url: `${SERVER}/emergencyCodes/generate`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: changeStatusEmergencyCodeData,
      loading: changeStatusEmergencyCodeLoading,
      error: changeStatusEmergencyCodeError,
    },
    executeChangeStatusEmergencyCode,
  ] = useAxios(
    {
      url: `${SERVER}/emergencyCodes/changeStatus`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (getEmergencyCodesData) {
      setEmergencyCodes(getEmergencyCodesData);
    }
  }, [getEmergencyCodesData]);

  useEffect(() => {
    if (generateEmergencyCodeData) {
      setCodeName("");
      executeGetEmergencyCodes();
      Swal.fire({
        icon: "success",
        title: "Emergency Code Generated.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [generateEmergencyCodeData, executeGetEmergencyCodes]);

  useEffect(() => {
    if (changeStatusEmergencyCodeData) {
      executeGetEmergencyCodes();
      Swal.fire({
        icon: "success",
        title: "Status Changed.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [changeStatusEmergencyCodeData, executeGetEmergencyCodes]);

  if (getEmergencyCodesLoading || generateEmergencyCodeLoading || changeStatusEmergencyCodeLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          textAlign: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="subtitle1">Wait a moment please</Typography>
      </div>
    );
  }

  if (getEmergencyCodesError || generateEmergencyCodeError || changeStatusEmergencyCodeError) {
    Swal.fire({
      icon: "error",
      title: "An error has occurred",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  const generateCode = () => {
    if (codeName.trim() === "") {
      setValidateForm(true);
    } else {
      setValidateForm(false);
      executeGenerateEmergencyCode({
        data: {
          name: codeName.trim(),
          creationDate: moment().format("YYYY-MM-DD"),
          codeType: seePatientInformation
        },
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Emergency Codes</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {userData.actionPermissions === 1 && (
        <Fragment>
          <Grid item xs={12} md={6}>
            <TextField
              id="name"
              error={codeName.trim() === "" && validateForm}
              helperText={
                codeName.trim() === "" && validateForm
                  ? "This field is require"
                  : ""
              }
              fullWidth
              type="text"
              label="Name"
              required
              value={codeName}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={(e) => {
                pasteValidation(e, 3);
                setCodeName(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">See all patients information?</FormLabel>
              <RadioGroup
                aria-labelledby="information-radio-buttons-group-label"
                defaultValue="1"
                name="information-radio-buttons-group"
                onChange={(e) => {
                  const value = e.target.value;
                  setSeePatientInformation(value);
                }}
              >
                <FormControlLabel value="1" control={<Radio checked={seePatientInformation === "1"} />} label="Yes" />
                <FormControlLabel value="0" control={<Radio checked={seePatientInformation === "0"} />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              alignSelf: "center",
            }}
          >
            <Button color="primary" variant="contained" onClick={generateCode}>
              Generate
            </Button>
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#fafafa" }}>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Code</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Creation Date</strong>
                </TableCell>
                <TableCell align="right">
                  <SettingsIcon style={{ color: "#000000" }} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emergencyCodes.length > 0 ? (
                emergencyCodes.map((emergencyCode, index) => (
                  <TableRow key={index} style={{ backgroundColor: emergencyCode.status === 1 ? "#c8e6c9" : "#ffcdd2" }}>
                    <TableCell>{emergencyCode.name}</TableCell>
                    <TableCell align="right">{emergencyCode.code}</TableCell>
                    <TableCell align="right">{emergencyCode.codeType === 1 ? 'Full Information' : 'Only Emergencies'}</TableCell>
                    <TableCell align="right">
                      {emergencyCode.creationDate}
                    </TableCell>
                    <TableCell align="right">
                      {userData.actionPermissions === 1 && (
                        <Tooltip title={emergencyCode.status === 1 ? "Disable code" : "Activate code"}>
                          <IconButton onClick={() => {
                            executeChangeStatusEmergencyCode({
                              data: {
                                id: emergencyCode.id,
                                status: emergencyCode.status === 1 ? 0 : 1,
                              }
                            })
                          }}>
                            {emergencyCode.status === 1 ? (
                              <CancelIcon color="secondary" />
                            ) : (
                              <DoneIcon style={{ color: "#4caf50" }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography align="center" variant="subtitle1">
                      No Emergency Codes
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
