import { Redirect } from "react-router-dom";

export default function ProtectedRoute({ userData, itemsMenu, children }) {

    if(userData && userData?.permissions !== 1) {
        const currentHash = window.location.hash.replace('#', '');
        const filterMenu = itemsMenu.filter(item => item.permissions >= userData?.permissions);
        const findItem = filterMenu.find(item => item.path === currentHash);

        if(currentHash === '/') {
            return <Redirect to={filterMenu[0].path} />;
        }

        if(findItem) {
            return findItem.component;
        
        }
        return <Redirect to='/login' />;
    }
    
    return children;
}
