import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Card,
  CardContent,
  Collapse,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CardActionArea,
  Dialog,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Clear as ClearIcon,
  AssignmentInd as AssignmentIndIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  DeveloperMode as DeveloperModeIcon,
  Visibility as VisibilityIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  MonetizationOn as MonetizationOnIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import {
  keyValidation,
  pasteValidation,
} from "../../helpers/inputHelpers";
import moment from "moment";
import QRCode from "react-qr-code";
import jwt from "jsonwebtoken";
import { FRONTEND, SERVER } from "../../config";
import ErrorComponent from "../componentsHelpers/error.js";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFPatients from "../componentsHelpers/PDFPatients";

function createData(
  id,
  firstName,
  lastName,
  address,
  phone,
  phone2,
  email,
  dateOfBirth,
  bloodType,
  gender,
  medicines,
  chronicMedicalConditions,
  medicalEquipment,
  allergies,
  immunizationHistory,
  mainDoctor,
  registrationDate,
  secureCode
) {
  return {
    id,
    firstName,
    lastName,
    address,
    phone,
    phone2,
    email,
    dateOfBirth,
    bloodType,
    gender,
    medicines,
    chronicMedicalConditions,
    medicalEquipment,
    allergies,
    immunizationHistory,
    mainDoctor,
    registrationDate,
    secureCode
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    ordering: true,
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
    ordering: true,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
    ordering: true,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    ordering: true,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    ordering: true,
  },
  {
    id: "registrationDate",
    numeric: true,
    disablePadding: false,
    label: "Registration Date",
    ordering: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: <SettingsIcon />,
    ordering: false,
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#fafafa" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.ordering ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <strong>{headCell.label}</strong>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textField: {
    width: "100%",
  },
  media: {
    height: 140,
  },
}));

function Patients(props) {
  const classes = useStyles();
  const userData = props.userData;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [patients, setPatients] = useState([]);
  const [searchPatients, setSearchPatients] = useState("");
  const [rows, setRows] = useState([]);
  const [showSection, setShowSection] = useState(0);
  const [idPatient, setIdPatien] = useState(0);
  const [namePatient, setNamePatient] = useState("");
  const [openCollapseTable, setOpenCollapseTable] = useState(0);
  const [reportPatientPayments, setReportPatientPayments] = useState([]);
  const [orderPatientReport, setOrderPatientReport] = useState(0);

  const [
    {
      data: getPatientsData,
      loading: getPatientsLoading,
      error: getPatientsError,
    },
    executeGetPatients,
  ] = useAxios(
    {
      url: `${SERVER}/patients/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: deletePatientData,
      loading: deletePatientLoading,
      error: deletePatientError,
    },
    executeDeletePatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/delete`,
      method: "DELETE",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (getPatientsData) {
      setPatients(getPatientsData);
      let rows = [];
      getPatientsData.map((patient) => {
        return rows.push(
          createData(
            patient.id,
            patient.firstName,
            patient.lastName,
            patient.address,
            patient.phone,
            patient.phone2,
            patient.email,
            patient.dateOfBirth,
            patient.bloodType,
            patient.gender,
            patient.medicines,
            patient.chronicMedicalConditions,
            patient.medicalEquipment,
            patient.allergies,
            patient.immunizationHistory,
            patient.mainDoctor,
            patient.registrationDate,
            patient.secureCode
          )
        );
      });

      const reportPatientPaymentsData = getPatientsData.filter(patient => patient.role === 1).map((patient) => ({
        patient: `${patient.firstName} ${patient.lastName}`,
        phone: patient.phone,
        email: patient.email,
        nextPayment: patient.nextPayment ? patient.nextPayment : 'Without payment'
      }));
      reportPatientPaymentsData.sort((a, b) => {
        const nameA = a.patient.toUpperCase();
        const nameB = b.patient.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      setReportPatientPayments(reportPatientPaymentsData);
    }
  }, [getPatientsData]);

  useEffect(() => {
    if (deletePatientData) {
      if (deletePatientData.error === 0) {
        executeGetPatients();
        Swal.fire({
          icon: "success",
          title: "The patient has been deleted",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: deletePatientData.message,
          showConfirmButton: true,
        });
      }
    }
  }, [deletePatientData, executeGetPatients]);

  useEffect(() => {
    const filterRows = () => {
      let rows = [];
      for (let x = 0; x < patients.length; x++) {
        if (
          (patients[x].firstName &&
            patients[x].firstName
              .toLowerCase()
              .indexOf(searchPatients.trim().toLowerCase()) !== -1) ||
          (patients[x].lastName &&
            patients[x].lastName
              .toLowerCase()
              .indexOf(searchPatients.trim().toLowerCase()) !== -1) ||
          (patients[x].address &&
            patients[x].address
              .toLowerCase()
              .indexOf(searchPatients.trim().toLowerCase()) !== -1) ||
          (patients[x].phone &&
            patients[x].phone
              .toLowerCase()
              .indexOf(searchPatients.trim().toLowerCase()) !== -1) ||
          (patients[x].email &&
            patients[x].email
              .toLowerCase()
              .indexOf(searchPatients.trim().toLowerCase()) !== -1)
        ) {
          rows.push(
            createData(
              patients[x].id,
              patients[x].firstName,
              patients[x].lastName,
              patients[x].address,
              patients[x].phone,
              patients[x].phone2,
              patients[x].email,
              patients[x].dateOfBirth,
              patients[x].bloodType,
              patients[x].gender,
              patients[x].medicines,
              patients[x].chronicMedicalConditions,
              patients[x].medicalEquipment,
              patients[x].allergies,
              patients[x].immunizationHistory,
              patients[x].mainDoctor,
              patients[x].registrationDate,
              patients[x].secureCode
            )
          );
        }
      }
      return rows;
    };

    setRows(searchPatients.trim() === "" ? patients : filterRows());
    if (searchPatients.trim() !== "") {
      setPage(0);
    }
  }, [searchPatients, patients]);

  if (getPatientsLoading || deletePatientLoading) {
    return <CircularProgress />;
  }

  if (getPatientsError || deletePatientError) {
    Swal.fire({
      icon: "error",
      title: "An error occurred on the server",
      showConfirmButton: true,
    });
  }

  const handleChangueSearchPatients = (e) => {
    setSearchPatients(e.target.value);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickGoToSavePatient = () => {
    setShowSection(1);
  };

  const handleClickGoToMedicalHistory = () => {
    setShowSection(2);
  };

  const handleClickGoToGenerateQRCode = () => {
    setShowSection(3);
  };

  const handleClickGoToPayments = () => {
    setShowSection(4);
  };

  const handleClickDeletePatient = (idPatient) => {
    Swal.fire({
      title: "Are you sure to remove the patient?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        executeDeletePatient({
          data: {
            idPatient: idPatient,
          },
        });
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Patients</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {showSection === 0 ? (
        <Fragment>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Ordering by</FormLabel>
              <RadioGroup
                row
                aria-labelledby="ordering-radio-group"
                name="ordering-radio-buttons-group"
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setOrderPatientReport(value);

                  if (value === 0) {
                    reportPatientPayments.sort((a, b) => {
                      const nameA = a.patient.toUpperCase();
                      const nameB = b.patient.toUpperCase();
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                    });
                  }
                  else {
                    reportPatientPayments.sort((a, b) => {
                      const nameA = a.nextPayment;
                      const nameB = b.nextPayment;
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                    });
                  }
                }}
              >
                <FormControlLabel value={0} control={<Radio checked={orderPatientReport === 0} />} label="Patient" />
                <FormControlLabel value={1} control={<Radio checked={orderPatientReport === 1} />} label="Next Payment" />
              </RadioGroup>
            </FormControl>
            <PDFDownloadLink style={{ textDecoration: 'none' }} document={<PDFPatients data={reportPatientPayments} />} fileName={`Patient´s Report.pdf`}>
              {({ blob, url, loading, error }) => (loading ? 'Loading report...' :
                <div>
                  <a href={url} without rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary">See Report</Button>
                  </a>
                  <Button variant="contained" color="primary" style={{ marginLeft: '15px' }}>Download Report</Button>
                </div>)}
            </PDFDownloadLink>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="searchPatients"
              type="text"
              label="Search"
              value={searchPatients}
              onChange={handleChangueSearchPatients}
            />
          </Grid>
          {userData.actionPermissions === 1 && (
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ background: "#0274ca", color: "white", float: "right" }}
                onClick={handleClickGoToSavePatient}
              >
                Add
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={3}>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.length > 0 ? (
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <Fragment key={index}>
                              <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell padding="checkbox">
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() =>
                                      setOpenCollapseTable(
                                        row.id === openCollapseTable
                                          ? 0
                                          : row.id
                                      )
                                    }
                                  >
                                    {row.id === openCollapseTable ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.firstName}
                                </TableCell>
                                <TableCell align="right">
                                  {row.lastName}
                                </TableCell>
                                <TableCell align="right">
                                  {row.address}
                                </TableCell>
                                <TableCell align="right">{row.phone}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell align="right">
                                  {moment(row.registrationDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Generate QR Code">
                                    <IconButton
                                      onClick={() => {
                                        handleClickGoToGenerateQRCode();
                                        setIdPatien(row.id);
                                        setNamePatient(
                                          `${row.firstName} ${row.lastName}`
                                        );
                                      }}
                                    >
                                      <DeveloperModeIcon
                                        style={{ color: "#000000" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Payments">
                                    <IconButton
                                      onClick={() => {
                                        handleClickGoToPayments();
                                        setIdPatien(row.id);
                                        setNamePatient(
                                          `${row.firstName} ${row.lastName}`
                                        );
                                      }}
                                    >
                                      <MonetizationOnIcon
                                        style={{ color: "#000000" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Medical History">
                                    <IconButton
                                      onClick={() => {
                                        handleClickGoToMedicalHistory();
                                        setIdPatien(row.id);
                                        setNamePatient(
                                          `${row.firstName} ${row.lastName}`
                                        );
                                      }}
                                    >
                                      <AssignmentIndIcon
                                        style={{ color: "#000000" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() => {
                                        handleClickGoToSavePatient();
                                        setIdPatien(row.id);
                                      }}
                                    >
                                      <EditIcon style={{ color: "#0274ca" }} />
                                    </IconButton>
                                  </Tooltip>
                                  {userData.actionPermissions === 1 && (
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          handleClickDeletePatient(row.id);
                                        }}
                                      >
                                        <DeleteIcon color="secondary" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={7}
                                >
                                  <Collapse
                                    in={row.id === openCollapseTable}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin={1}>
                                      <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                      >
                                        <strong>Details</strong>
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead
                                          style={{ background: "#fafafa" }}
                                        >
                                          <TableRow>
                                            <TableCell>
                                              <strong>Secure Code</strong>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              {row.secureCode}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No patients were found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Fragment>
      ) : showSection === 1 ? (
        <SavePatientInformation
          setShowSection={setShowSection}
          executeGetPatients={executeGetPatients}
          idPatient={idPatient}
          setIdPatien={setIdPatien}
          userData={userData}
        />
      ) : showSection === 2 ? (
        <MedicalHistory
          setShowSection={setShowSection}
          idPatient={idPatient}
          setIdPatien={setIdPatien}
          namePatient={namePatient}
        />
      ) : showSection === 3 ? (
        <GenerateQRCode
          setShowSection={setShowSection}
          idPatient={idPatient}
          setIdPatien={setIdPatien}
          namePatient={namePatient}
        />
      ) : showSection === 4 ? (
        <Payments
          setShowSection={setShowSection}
          idPatient={idPatient}
          setIdPatien={setIdPatien}
          namePatient={namePatient}
          userData={userData}
        />
      ) : null}
    </Grid>
  );
}

function SavePatientInformation(props) {
  const classes = useStyles();

  const setShowSection = props.setShowSection;
  const executeGetPatients = props.executeGetPatients;
  const idPatient = props.idPatient;
  const setIdPatien = props.setIdPatien;
  const userData = props.userData;

  const [infoPatient, setInfoPatient] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    phone: "",
    phone2: "",
    email: "",
    dateOfBirth: "",
    bloodType: "",
    gender: "",
    medicines: "",
    chronicMedicalConditions: "",
    medicalEquipment: "",
    allergies: "",
    immunizationHistory: "",
    mainDoctor: 0,
    mainHospital: 0,
  });
  const [patientPicture, setPatientPicture] = useState(null);
  const [emergencyContacts, setEmergencyContacts] = useState({
    ids: [0],
    firstNames: [""],
    lastNames: [""],
    phones: [""],
    emails: [""],
  });
  const [documentsPatient, setDocumentPatient] = useState({
    file: null,
    description: "",
  });
  const [documents, setDocuments] = useState({
    ids: [],
    files: [],
    descriptions: [],
  });
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [disabledInfoPatients, setDisabledInfoPatients] = useState({
    registrationDate: "",
    secureCode: "",
    currentPayment: "",
    nextPayment: "",
  });
  const [validateForm, setValidateForm] = useState(false);
  const [validateFormDocuments, setValidateFormDocuments] = useState(false);
  const [validateDescriptionDocuments, setValidateDescriptionDocuments] = useState(false);

  const [
    {
      data: savePatientsData,
      loading: savePatientsLoading,
      error: savePatientsError,
    },
    executeSavePatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/save`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: getPatientData,
      loading: getPatientLoading,
      error: getPatientError,
    },
    executeGetPatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/${idPatient}`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: getActivesDoctorsData,
      loading: getActivesDoctorsLoading,
      error: getActivesDoctorsError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/doctors/actives`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: getActivesHospitalsData,
      loading: getActivesHospitalsLoading,
      error: getActivesHospitalsError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/actives`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: addPicturePatientData,
      loading: addPicturePatientLoading,
      error: addPicturePatientError,
    },
    executeAddPicturePatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/addPicture`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: addDocumentPatientData,
      loading: addDocumentPatientLoading,
      error: addDocumentPatientError,
    },
    executeAddDocumentPatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/addDocument`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: deleteEmergencyContactData,
      loading: deleteEmergencyContactLoading,
      error: deleteEmergencyContactError,
    },
    executeDeleteEmergencyContact,
  ] = useAxios(
    {
      url: `${SERVER}/patients/deleteEmergencyContact`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: deleteDocumentData,
      loading: deleteDocumentLoading,
      error: deleteDocumentError,
    },
    executeDeleteDocument,
  ] = useAxios(
    {
      url: `${SERVER}/patients/deleteDocument`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: updateDocumentDescriptionData,
      loading: updateDocumentDescriptionLoading,
      error: updateDocumentDescriptionError,
    },
    executeUpdateDocumentDescription,
  ] = useAxios(
    {
      url: `${SERVER}/patients/updateDocumentDescription`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (idPatient !== 0) {
      executeGetPatient();
    }
  }, [idPatient, executeGetPatient]);

  useEffect(() => {
    if (savePatientsData) {
      let iconSwal = 'warning'
      if (!savePatientsData.error) {
        iconSwal = 'success';
        executeGetPatients();
        setShowSection(0);
        setInfoPatient({
          id: 0,
          firstName: "",
          lastName: "",
          address: "",
          address2: "",
          phone: "",
          phone2: "",
          email: "",
          dateOfBirth: "",
          bloodType: "",
          gender: "",
          medicines: "",
          chronicMedicalConditions: "",
          medicalEquipment: "",
          allergies: "",
          immunizationHistory: "",
          mainDoctor: 0,
          mainHospital: 0,
        });
        setPatientPicture(null);
        setEmergencyContacts({
          ids: [0],
          firstNames: [""],
          lastNames: [""],
          phones: [""],
          emails: [""],
        });
        setDocumentPatient({
          file: null,
          description: "",
        });
        setDocuments({
          ids: [],
          files: [],
          descriptions: [],
        });
        setDisabledInfoPatients({
          registrationDate: "",
          secureCode: "",
          currentPayment: "",
          nextPayment: "",
        });
        setValidateForm(false);
        setValidateFormDocuments(false);
        setValidateDescriptionDocuments(false);
      }
      Swal.fire({
        icon: iconSwal,
        title: savePatientsData.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [savePatientsData, executeGetPatients, setShowSection]);

  useEffect(() => {
    if (getPatientData) {
      setInfoPatient({
        id: getPatientData[0].id ? getPatientData[0].id : 0,
        firstName: getPatientData[0].firstName
          ? getPatientData[0].firstName
          : "",
        lastName: getPatientData[0].lastName ? getPatientData[0].lastName : "",
        address: getPatientData[0].address ? getPatientData[0].address : "",
        address2: getPatientData[0].address2 ? getPatientData[0].address2 : "",
        phone: getPatientData[0].phone ? getPatientData[0].phone : "",
        phone2: getPatientData[0].phone2 ? getPatientData[0].phone2 : "",
        email: getPatientData[0].email ? getPatientData[0].email : "",
        dateOfBirth: getPatientData[0].dateOfBirth
          ? getPatientData[0].dateOfBirth
          : "",
        bloodType: getPatientData[0].bloodType
          ? getPatientData[0].bloodType
          : "",
        gender: getPatientData[0].gender ? getPatientData[0].gender : "",
        medicines: getPatientData[0].medicines
          ? getPatientData[0].medicines
          : "",
        chronicMedicalConditions: getPatientData[0].chronicMedicalConditions
          ? getPatientData[0].chronicMedicalConditions
          : "",
        medicalEquipment: getPatientData[0].medicalEquipment
          ? getPatientData[0].medicalEquipment
          : "",
        allergies: getPatientData[0].allergies
          ? getPatientData[0].allergies
          : "",
        immunizationHistory: getPatientData[0].immunizationHistory
          ? getPatientData[0].immunizationHistory
          : "",
        mainDoctor: getPatientData[0].mainDoctor
          ? getPatientData[0].mainDoctor
          : 0,
        mainHospital: getPatientData[0].mainHospital
          ? getPatientData[0].mainHospital
          : 0,
      });
      setPatientPicture(getPatientData[0].picture);

      let ids = [];
      let firstNames = [];
      let lastNames = [];
      let phones = [];
      let emails = [];
      for (let x = 0; x < getPatientData[0].emergencyContacts.length; x++) {
        ids.push(getPatientData[0].emergencyContacts[x].id);
        firstNames.push(getPatientData[0].emergencyContacts[x].firstName);
        lastNames.push(getPatientData[0].emergencyContacts[x].lastName);
        phones.push(getPatientData[0].emergencyContacts[x].phone);
        emails.push(
          getPatientData[0].emergencyContacts[x].email
            ? getPatientData[0].emergencyContacts[x].email
            : ""
        );
      }

      setEmergencyContacts({
        ids: ids,
        firstNames: firstNames,
        lastNames: lastNames,
        phones: phones,
        emails: emails,
      });

      let idsDocuments = [];
      let files = [];
      let descriptions = [];
      for (let x = 0; x < getPatientData[0].documents.length; x++) {
        idsDocuments.push(getPatientData[0].documents[x].id);
        files.push(getPatientData[0].documents[x].document);
        descriptions.push(
          getPatientData[0].documents[x].description
            ? getPatientData[0].documents[x].description
            : ""
        );
      }

      setDocuments({
        ids: idsDocuments,
        files: files,
        descriptions: descriptions,
      });
      setDisabledInfoPatients({
        registrationDate: moment(getPatientData[0].registrationDate).format(
          "YYYY-MM-DD"
        ),
        secureCode: getPatientData[0].secureCode,
        currentPayment:
          getPatientData[0].payments.length > 0
            ? moment(getPatientData[0].payments[0].paymentDate).format(
              "YYYY-MM-DD"
            )
            : "No Payment",
        nextPayment:
          getPatientData[0].payments.length > 0
            ? moment(getPatientData[0].payments[0].nextPayment).format(
              "YYYY-MM-DD"
            )
            : "No Payment",
      });
    }
  }, [getPatientData]);

  useEffect(() => {
    if (getActivesDoctorsData) {
      setDoctors(getActivesDoctorsData);
    }
  }, [getActivesDoctorsData]);

  useEffect(() => {
    if (getActivesHospitalsData) {
      setHospitals(getActivesHospitalsData);
    }
  }, [getActivesHospitalsData]);

  useEffect(() => {
    if (addPicturePatientData) {
      if (addPicturePatientData.error) {
        Swal.fire({
          icon: "warning",
          title: addPicturePatientData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setPatientPicture(addPicturePatientData.message);
        if (addPicturePatientData.update) {
          Swal.fire({
            icon: "success",
            title: addPicturePatientData.messageUpdate,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }
  }, [addPicturePatientData]);

  useEffect(() => {
    if (addDocumentPatientData) {
      if (addDocumentPatientData.error) {
        Swal.fire({
          icon: "warning",
          title: addDocumentPatientData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        window.scrollTo(0, document.body.scrollHeight);
        let newDocuments = documents.files;
        newDocuments.push(addDocumentPatientData.message);
        setDocuments((documents) => ({
          ...documents,
          files: newDocuments,
        }));
        setDocumentPatient({
          file: null,
          description: "",
        });
        if (addDocumentPatientData.dbSave) {
          Swal.fire({
            icon: "success",
            title: addDocumentPatientData.messageSave,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }
  }, [addDocumentPatientData, documents.files]);

  useEffect(() => {
    if (deleteEmergencyContactData) {
      if (deleteEmergencyContactData.error) {
        Swal.fire({
          icon: "warning",
          title: deleteEmergencyContactData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        executeGetPatient();
        Swal.fire({
          icon: "success",
          title: "Emergency contact removed.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [deleteEmergencyContactData, executeGetPatient]);

  useEffect(() => {
    if (deleteDocumentData) {
      if (deleteDocumentData.error) {
        Swal.fire({
          icon: "warning",
          title: deleteDocumentData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        executeGetPatient();
        Swal.fire({
          icon: "success",
          title: "Document removed.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [deleteDocumentData, executeGetPatient]);

  useEffect(() => {
    if (updateDocumentDescriptionData) {
      if (updateDocumentDescriptionData.error) {
        Swal.fire({
          icon: "warning",
          title: "Error while updated document",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        executeGetPatient();
        Swal.fire({
          icon: "success",
          title: "Document updated.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [updateDocumentDescriptionData, executeGetPatient]);

  if (
    savePatientsLoading ||
    getPatientLoading ||
    getActivesDoctorsLoading ||
    getActivesHospitalsLoading ||
    addPicturePatientLoading ||
    addDocumentPatientLoading ||
    deleteEmergencyContactLoading ||
    deleteDocumentLoading ||
    updateDocumentDescriptionLoading
  ) {
    return <CircularProgress />;
  }

  if (
    savePatientsError ||
    getPatientError ||
    getActivesDoctorsError ||
    getActivesHospitalsError ||
    addPicturePatientError ||
    addDocumentPatientError ||
    deleteEmergencyContactError ||
    deleteDocumentError ||
    updateDocumentDescriptionError
  ) {
    Swal.fire({
      icon: "error",
      title: "An error has occurred",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  const handleClickReturn = () => {
    setShowSection(0);
    setIdPatien(0);
    setInfoPatient({
      id: 0,
      firstName: "",
      lastName: "",
      address: "",
      address2: "",
      phone: "",
      phone2: "",
      email: "",
      dateOfBirth: "",
      bloodType: "",
      gender: "",
      medicines: "",
      chronicMedicalConditions: "",
      medicalEquipment: "",
      allergies: "",
      immunizationHistory: "",
      mainDoctor: 0,
      mainHospital: 0,
    });
    setPatientPicture(null);
    setEmergencyContacts({
      ids: [0],
      firstNames: [""],
      lastNames: [""],
      phones: [""],
      emails: [""],
    });
    setDocumentPatient({
      file: null,
      description: "",
    });
    setDocuments({
      ids: [],
      files: [],
      descriptions: [],
    });
    setDisabledInfoPatients({
      registrationDate: "",
      secureCode: "",
      currentPayment: "",
      nextPayment: "",
    });
    setValidateForm(false);
    setValidateFormDocuments(false);
    setValidateDescriptionDocuments(false);
  };

  const handleChangeInputsSavePatient = (e) => {
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      pasteValidation(e, 1);
    } else if (
      e.target.id === "address" ||
      e.target.id === "address2" ||
      e.target.id === "medicines" ||
      e.target.id === "chronicMedicalConditions" ||
      e.target.id === "medicalEquipment" ||
      e.target.id === "allergies" ||
      e.target.id === "immunizationHistory"
    ) {
      pasteValidation(e, 3);
    } else if (e.target.id === "phone" || e.target.id === "phone2") {
      pasteValidation(e, 2);
    } else if (e.target.id === "email") {
      pasteValidation(e, 4);
    }

    setInfoPatient({
      ...infoPatient,
      [e.target.id]: e.target.value,
    });
  };

  const handleClickSavePatient = () => {
    const {
      id,
      firstName,
      lastName,
      address,
      address2,
      phone,
      phone2,
      email,
      dateOfBirth,
      bloodType,
      gender,
      medicines,
      chronicMedicalConditions,
      medicalEquipment,
      allergies,
      immunizationHistory,
      mainDoctor,
      mainHospital,
    } = infoPatient;
    const { ids, firstNames, lastNames, phones, emails } = emergencyContacts;
    const firstNamesArray = firstNames.map((firstName) => {
      return firstName.trim();
    });
    const lastNamesArray = lastNames.map((lastName) => {
      return lastName.trim();
    });
    const phonesArray = phones.map((phone) => {
      return phone.trim();
    });
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      phone.trim() === "" ||
      mainDoctor === 0 ||
      mainDoctor === "0" ||
      mainHospital === 0 ||
      mainHospital === "0" ||
      firstNamesArray.indexOf("") !== -1 ||
      lastNamesArray.indexOf("") !== -1 ||
      phonesArray.indexOf("") !== -1
    ) {
      Swal.fire({
        icon: "warning",
        title: "Fill in all the required fields",
        showConfirmButton: false,
        timer: 3000,
      });
      setValidateForm(true);
    } else {
      setValidateForm(false);
      executeSavePatient({
        data: {
          id: id,
          firstName: firstName,
          lastName: lastName,
          address: address,
          address2: address2,
          phone: phone,
          phone2: phone2,
          email: email,
          dateOfBirth: dateOfBirth,
          bloodType: bloodType,
          gender: gender,
          medicines: medicines,
          chronicMedicalConditions: chronicMedicalConditions,
          medicalEquipment: medicalEquipment,
          allergies: allergies,
          immunizationHistory: immunizationHistory,
          mainDoctor: parseInt(mainDoctor),
          mainHospital: parseInt(mainHospital),
          idsEmergencyContacts: ids,
          firstNamesEmergencyContacts: firstNames,
          lastNamesEmergencyContacts: lastNames,
          phonesEmergencyContacts: phones,
          emailsEmergencyContacts: emails,
          picture: patientPicture,
          idsDocuments: documents.ids,
          documents: documents.files,
          descriptionsDocuments: documents.descriptions,
        },
      });
    }
  };

  const handleClickCleanPatientForm = () => {
    setInfoPatient({
      id: 0,
      firstName: "",
      lastName: "",
      address: "",
      address2: "",
      phone: "",
      phone2: "",
      email: "",
      dateOfBirth: "",
      bloodType: "",
      gender: "",
      medicines: "",
      chronicMedicalConditions: "",
      medicalEquipment: "",
      allergies: "",
      immunizationHistory: "",
      mainDoctor: 0,
      mainHospital: 0,
    });
    setPatientPicture(null);
    setEmergencyContacts({
      ids: [0],
      firstNames: [""],
      lastNames: [""],
      phones: [""],
      emails: [""],
    });
    setDocumentPatient({
      file: null,
      description: "",
    });
    setDocuments({
      ids: [],
      files: [],
      descriptions: [],
    });
    setDisabledInfoPatients({
      registrationDate: "",
      secureCode: "",
      currentPayment: "",
      nextPayment: "",
    });
  };

  const handleClickAddEmergencyContact = () => {
    const { ids, firstNames, lastNames, phones, emails } = emergencyContacts;
    const newIds = ids;
    const newFirstNames = firstNames;
    const newLastNames = lastNames;
    const newPhones = phones;
    const newEmails = emails;

    newIds.push(0);
    newFirstNames.push("");
    newLastNames.push("");
    newPhones.push("");
    newEmails.push("");

    setEmergencyContacts({
      ids: newIds,
      firstNames: newFirstNames,
      lastNames: newLastNames,
      phones: newPhones,
      emails: newEmails,
    });
  };

  const handleClickRemoveEmergencyContact = (pos) => {
    const { ids, firstNames, lastNames, phones, emails } = emergencyContacts;
    const newIds = ids;
    const newFirstNames = firstNames;
    const newLastNames = lastNames;
    const newPhones = phones;
    const newEmails = emails;

    newIds.splice(pos, 1);
    newFirstNames.splice(pos, 1);
    newLastNames.splice(pos, 1);
    newPhones.splice(pos, 1);
    newEmails.splice(pos, 1);

    setEmergencyContacts({
      ids: newIds,
      firstNames: newFirstNames,
      lastNames: newLastNames,
      phones: newPhones,
      emails: newEmails,
    });
  };

  const handleClickAddDocument = () => {
    const { ids, descriptions } = documents;
    const { file, description } = documentsPatient;
    if (file === null || description.trim() === "") {
      setValidateFormDocuments(true);
    } else {
      setValidateFormDocuments(false);
      const newIds = ids;
      const newDescriptions = descriptions;

      newIds.push(0);
      newDescriptions.push(description);

      setDocuments({
        ...documents,
        ids: newIds,
        descriptions: newDescriptions,
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("description", description);
      formData.append("code", moment().format("YYYYMMDDHmmssSS"));
      formData.append("idPatient", idPatient);

      executeAddDocumentPatient({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  };

  const handleClickRemoveDocument = (pos) => {
    Swal.fire({
      title: "Are you sure to remove the document?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (idPatient === 0) {
          const { ids, files, descriptions } = documents;
          const newIds = ids;
          const newFiles = files;
          const newDescriptions = descriptions;

          newIds.splice(pos, 1);
          newFiles.splice(pos, 1);
          newDescriptions.splice(pos, 1);

          setDocuments({
            ids: newIds,
            files: newFiles,
            descriptions: newDescriptions,
          });
        } else {
          executeDeleteDocument({
            data: {
              idDocument: documents.ids[pos],
              pos: pos,
            },
          });
        }
      }
    });
  };

  const addPatientPicture = (e) => {
    if (e.target.files.length > 0) {
      if (patientPicture) {
        Swal.fire({
          title: "Are you sure to replace the patient picture?",
          text: "The previous picture will be deleted.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f50057",
          cancelButtonColor: "#0274ca",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("code", moment().format("YYYYMMDDHmmssSS"));
            formData.append("oldPicture", patientPicture);
            formData.append("action", infoPatient.id === 0 ? 1 : 2);
            formData.append("id", infoPatient.id);

            executeAddPicturePatient({
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        });
      } else {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("code", moment().format("YYYYMMDDHmmssSS"));
        formData.append("oldPicture", "");
        formData.append("action", infoPatient.id === 0 ? 1 : 2);
        formData.append("id", infoPatient.id);

        executeAddPicturePatient({
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    }
  };

  return (
    <Paper elevation={3} style={{ padding: 15 }}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={userData.actionPermissions === 1 ? 6 : 12}>
          <Typography variant="h6">
            <Tooltip title="Back">
              <IconButton onClick={handleClickReturn}>
                <ArrowBackIcon style={{ color: "#0274ca" }} />
              </IconButton>
            </Tooltip>
            Save Patient Information
          </Typography>
        </Grid>
        {userData.actionPermissions === 1 && (
          <Grid item xs={12} md={6}>
            <Tooltip title="Clean" style={{ float: "right" }}>
              <IconButton onClick={handleClickCleanPatientForm}>
                <ClearIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save" style={{ float: "right" }}>
              <IconButton onClick={handleClickSavePatient}>
                <SaveIcon style={{ color: "#000000" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={12} md={idPatient > 0 ? 6 : 12}>
          <div
            style={{
              height: "202px",
              width: "202px",
              border: "1px solid black",
              marginBottom: "10px",
            }}
          >
            {patientPicture && (
              <img
                src={`${SERVER}/files/images/${patientPicture}`}
                alt={patientPicture}
                height="200"
                width="200"
              />
            )}
          </div>
          {userData.actionPermissions === 1 && (
            <input type="file" accept="image/*" onChange={addPatientPicture} />
          )}
        </Grid>
        {idPatient > 0 && (
          <Grid item xs={12} md={6} style={{ alignSelf: "center" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="registrationDate"
                  className={classes.textField}
                  type="text"
                  label="Registration Date"
                  value={disabledInfoPatients.registrationDate}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="secureCode"
                  className={classes.textField}
                  type="text"
                  label="Secure Code"
                  value={disabledInfoPatients.secureCode}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="currentPayment"
                  className={classes.textField}
                  type="text"
                  label="Current Payment"
                  value={disabledInfoPatients.currentPayment}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="nextPayment"
                  className={classes.textField}
                  type="text"
                  label="Next Payment"
                  value={disabledInfoPatients.nextPayment}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <TextField
            id="firstName"
            disabled={userData.actionPermissions !== 1}
            error={infoPatient.firstName.trim() === "" && validateForm}
            helperText={
              infoPatient.firstName.trim() === "" && validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            type="text"
            label="First Name"
            required
            value={infoPatient.firstName}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 1);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="lastName"
            disabled={userData.actionPermissions !== 1}
            error={infoPatient.lastName.trim() === "" && validateForm}
            helperText={
              infoPatient.lastName.trim() === "" && validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            type="text"
            label="Last Name"
            required
            value={infoPatient.lastName}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 1);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="address"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Address 1"
            value={infoPatient.address}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="address2"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Address 2"
            value={infoPatient.address2}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="phone"
            disabled={userData.actionPermissions !== 1}
            error={infoPatient.phone.trim() === "" && validateForm}
            helperText={
              infoPatient.phone.trim() === "" && validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            type="text"
            label="Phone"
            required
            inputProps={{
              maxLength: 15,
            }}
            value={infoPatient.phone}
            onKeyPress={(e) => {
              keyValidation(e, 2);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="phone2"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Phone 2"
            inputProps={{
              maxLength: 15,
            }}
            value={infoPatient.phone2}
            onKeyPress={(e) => {
              keyValidation(e, 2);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="email"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Email"
            inputProps={{
              maxLength: 100,
            }}
            value={infoPatient.email}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="dateOfBirth"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            label="Date Of Birth"
            value={infoPatient.dateOfBirth}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="bloodType"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Blood Type"
            value={infoPatient.bloodType}
            onChange={handleChangeInputsSavePatient}
          >
            <option value="">Select a blood type</option>
            <option value="A+">A+</option>
            <option value="B+">B+</option>
            <option value="A-">A-</option>
            <option value="B-">B-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="gender"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Gender"
            value={infoPatient.gender}
            onChange={handleChangeInputsSavePatient}
          >
            <option value="">Select a gender</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="mainDoctor"
            disabled={userData.actionPermissions !== 1}
            error={
              (infoPatient.mainDoctor === 0 ||
                infoPatient.mainDoctor === "0") &&
              validateForm
            }
            helperText={
              (infoPatient.mainDoctor === 0 ||
                infoPatient.mainDoctor === "0") &&
                validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Main Doctor"
            required
            value={infoPatient.mainDoctor}
            onChange={handleChangeInputsSavePatient}
          >
            <option value={0}>Select a Main Doctor</option>
            {doctors.map((doctor, index) => (
              <option
                key={index}
                value={doctor.id}
              >{`${doctor.firstName} ${doctor.lastName} (${doctor.phone})`}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="mainHospital"
            disabled={userData.actionPermissions !== 1}
            error={
              (infoPatient.mainHospital === 0 ||
                infoPatient.mainHospital === "0") &&
              validateForm
            }
            helperText={
              (infoPatient.mainHospital === 0 ||
                infoPatient.mainHospital === "0") &&
                validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Main Hospital"
            required
            value={infoPatient.mainHospital}
            onChange={handleChangeInputsSavePatient}
          >
            <option value={0}>Select a Main Hospital</option>
            {hospitals.map((hospital, index) => (
              <option
                key={index}
                value={hospital.id}
              >{`${hospital.name} (${hospital.phone})`}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="medicines"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Medicines"
            variant="outlined"
            multiline
            rows={4}
            value={infoPatient.medicines}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="chronicMedicalConditions"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Chronic Medical Conditions"
            variant="outlined"
            multiline
            rows={4}
            value={infoPatient.chronicMedicalConditions}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="medicalEquipment"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Medical Equipment"
            variant="outlined"
            multiline
            rows={4}
            value={infoPatient.medicalEquipment}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="allergies"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Allergies"
            variant="outlined"
            multiline
            rows={4}
            value={infoPatient.allergies}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="immunizationHistory"
            disabled={userData.actionPermissions !== 1}
            className={classes.textField}
            type="text"
            label="Immunization History"
            variant="outlined"
            multiline
            rows={4}
            value={infoPatient.immunizationHistory}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSavePatient}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Emergency Contacts
            {userData.actionPermissions === 1 && (
              <Tooltip title="Add">
                <IconButton onClick={handleClickAddEmergencyContact}>
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {emergencyContacts.ids.map((idEmergencyContact, index) => (
          <Fragment key={index}>
            <Grid item xs={12} md={2}>
              <TextField
                id={"firstNameEmergencyContacts" + index}
                disabled={userData.actionPermissions !== 1}
                error={
                  emergencyContacts.firstNames[index].trim() === "" &&
                  validateForm
                }
                helperText={
                  emergencyContacts.firstNames[index].trim() === "" &&
                    validateForm
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="First Name"
                required
                value={emergencyContacts.firstNames[index]}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 1);
                }}
                onChange={(e) => {
                  pasteValidation(e, 1);
                  let newFirstNames = emergencyContacts.firstNames;
                  newFirstNames[index] = e.target.value;
                  setEmergencyContacts({
                    ...emergencyContacts,
                    firstNames: newFirstNames,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id={"lastNameEmergencyContacts" + index}
                disabled={userData.actionPermissions !== 1}
                error={
                  emergencyContacts.lastNames[index].trim() === "" &&
                  validateForm
                }
                helperText={
                  emergencyContacts.lastNames[index].trim() === "" &&
                    validateForm
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="Last Name"
                required
                value={emergencyContacts.lastNames[index]}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 1);
                }}
                onChange={(e) => {
                  pasteValidation(e, 1);
                  let newLastNames = emergencyContacts.lastNames;
                  newLastNames[index] = e.target.value;
                  setEmergencyContacts({
                    ...emergencyContacts,
                    lastNames: newLastNames,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id={"phoneEmergencyContacts" + index}
                disabled={userData.actionPermissions !== 1}
                error={
                  emergencyContacts.phones[index].trim() === "" && validateForm
                }
                helperText={
                  emergencyContacts.phones[index].trim() === "" && validateForm
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="Phone"
                inputProps={{
                  maxLength: 15,
                }}
                required
                value={emergencyContacts.phones[index]}
                onKeyPress={(e) => {
                  keyValidation(e, 2);
                }}
                onChange={(e) => {
                  pasteValidation(e, 2);
                  let newPhones = emergencyContacts.phones;
                  newPhones[index] = e.target.value;
                  setEmergencyContacts({
                    ...emergencyContacts,
                    phones: newPhones,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id={"emailEmergencyContacts" + index}
                disabled={userData.actionPermissions !== 1}
                className={classes.textField}
                type="text"
                label="Email"
                inputProps={{
                  maxLength: 100,
                }}
                value={emergencyContacts.emails[index]}
                onKeyPress={(e) => {
                  keyValidation(e, 4);
                }}
                onChange={(e) => {
                  pasteValidation(e, 4);
                  let newEmails = emergencyContacts.emails;
                  newEmails[index] = e.target.value;
                  setEmergencyContacts({
                    ...emergencyContacts,
                    emails: newEmails,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {userData.actionPermissions === 1 && index !== 0 && (
                <Tooltip title="Remove">
                  <IconButton
                    onClick={() => {
                      if (idEmergencyContact !== 0) {
                        Swal.fire({
                          title:
                            "Are you sure to remove the emergency contact?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#f50057",
                          cancelButtonColor: "#0274ca",
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            executeDeleteEmergencyContact({
                              data: {
                                idEmergencyContact: idEmergencyContact,
                              },
                            });
                          }
                        });
                      } else {
                        handleClickRemoveEmergencyContact(index);
                      }
                    }}
                  >
                    <RemoveIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Documents
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {userData.actionPermissions === 1 && (
          <Fragment>
            <Grid item xs={12} md={5}>
              <TextField
                id={"descriptionDocument"}
                disabled={userData.actionPermissions !== 1}
                className={classes.textField}
                type="text"
                label="Description"
                error={
                  documentsPatient.description.trim() === "" &&
                  validateFormDocuments
                }
                helperText={
                  documentsPatient.description.trim() === "" &&
                    validateFormDocuments
                    ? "This field is require"
                    : ""
                }
                required
                value={documentsPatient.description}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 3);
                }}
                onChange={(e) => {
                  pasteValidation(e, 3);
                  setDocumentPatient({
                    ...documentsPatient,
                    description: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{ alignSelf: "end", textAlign: "center" }}
            >
              <input
                type="file"
                accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.xls,.xlsx,.csv,.ods"
                onChange={(e) => {
                  setDocumentPatient({
                    ...documentsPatient,
                    file: e.target.files.length > 0 ? e.target.files[0] : null,
                  });
                }}
              />
              <Typography
                variant="subtitle2"
                style={{ color: "#f44336", fontSize: "12px" }}
              >
                {documentsPatient.file === null && validateFormDocuments
                  ? "This field is require"
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickAddDocument}
              >
                Add
              </Button>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "#fafafa" }}>
                <TableRow>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>File</strong>
                  </TableCell>
                  <TableCell align="right">
                    <SettingsIcon style={{ color: "#000000" }} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.files.length > 0 ? (
                  documents.files.map((file, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <TextField
                          id={"documentDescription" + index}
                          disabled={userData.actionPermissions !== 1}
                          className={classes.textField}
                          type="text"
                          error={
                            documents.descriptions[index].trim() === "" &&
                            validateDescriptionDocuments
                          }
                          helperText={
                            documents.descriptions[index].trim() === "" &&
                              validateDescriptionDocuments
                              ? "This field is require"
                              : ""
                          }
                          required
                          inputProps={{
                            maxLength: 100,
                          }}
                          value={documents.descriptions[index]}
                          onKeyPress={(e) => {
                            keyValidation(e, 3);
                          }}
                          onChange={(e) => {
                            pasteValidation(e, 3);
                            const value = e.target.value;
                            const newDescriptions = documents.descriptions;
                            newDescriptions[index] = value;
                            setDocuments({
                              ...documents,
                              descriptions: newDescriptions
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{file}</TableCell>
                      <TableCell align="right">
                        {userData.actionPermissions === 1 && (
                          <Tooltip title="Save Description">
                            <IconButton
                              onClick={() => {
                                if (documents.descriptions[index].trim() === "") {
                                  setValidateDescriptionDocuments(true);
                                }
                                else {
                                  setValidateDescriptionDocuments(false);
                                  executeUpdateDocumentDescription({
                                    data: {
                                      idDocument: documents.ids[index],
                                      description: documents.descriptions[index]
                                    }
                                  });
                                }
                              }}
                            >
                              <SaveIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="See Document">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `${SERVER}/files/docs/${documents.files[index]}`
                              );
                            }}
                          >
                            <VisibilityIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        {userData.actionPermissions === 1 && (
                          <Tooltip title="Remove File">
                            <IconButton
                              onClick={() => handleClickRemoveDocument(index)}
                            >
                              <CancelIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No files uploaded
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}

function MedicalHistory(props) {
  const setShowSection = props.setShowSection;
  const idPatient = props.idPatient;
  const setIdPatien = props.setIdPatien;
  const namePatient = props.namePatient;

  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [visits, setVisits] = useState([]);
  const [idVisit, setIdVisit] = useState(0);
  const [notes, setNotes] = useState([]);

  const [
    {
      data: getPatientVisitsData,
      loading: getPatientVisitsLoading,
      error: getPatientVisitsError,
    },
    executePatientVisitsData,
  ] = useAxios(
    {
      url: `${SERVER}/patients/visits/${idPatient}`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: getUpdateVisitNotes,
      loading: loadingUpdateVisitNotes,
      error: errorUpdateVisitNotes,
    },
    executeUpdateVisitNotes,
  ] = useAxios(
    {
      url: `${SERVER}/patients/updateVisitNotes/${idVisit}`,
      method: "PUT",
    },
    {
      manual: true,
      useCache: false,
    }
  );

  useEffect(() => {
    if (getPatientVisitsData) {
      setVisits(getPatientVisitsData);
    }
  }, [getPatientVisitsData]);

  useEffect(() => {
    if (getUpdateVisitNotes && !getUpdateVisitNotes.error) {
      executePatientVisitsData();
      handleCloseNotesDialog();
      Swal.fire({
        icon: "success",
        title: "The notes has been saved",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [getUpdateVisitNotes, executePatientVisitsData]);

  if (getPatientVisitsLoading || loadingUpdateVisitNotes) {
    return <CircularProgress />;
  }

  if (getPatientVisitsError || errorUpdateVisitNotes) {
    return <ErrorComponent />;
  }

  const handleClickReturn = () => {
    setShowSection(0);
    setIdPatien(0);
  };

  const handleClickOpenNotesDialog = (visit) => {
    setIdVisit(visit.id);
    setNotes(visit.notes ? visit.notes : '');
    setOpenNotesDialog(true);
  };

  const handleCloseNotesDialog = () => {
    setOpenNotesDialog(false);
  };

  const handleSaveNote = () => {
    executeUpdateVisitNotes({
      data: {
        notes: notes
      }
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          {namePatient}´s Medical History
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {visits.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ padding: 10 }}>
                {visits.length} visits
              </Typography>
            </Grid>
          )}
          {visits.length > 0 ? (
            visits.map((visits, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardActionArea onClick={() => {
                    handleClickOpenNotesDialog(visits)
                  }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {`${visits.hospital ? visits.hospital : visits.doctor}`}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div">
                        {`${visits.hour.substr(0, 5)} ${visits.date}`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    No visits
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        id="notesDialog"
        maxWidth="lg"
        fullWidth
        open={openNotesDialog}
        onClose={handleCloseNotesDialog}
      >
        <Grid container style={{ padding: '24px' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Visit´s Notes</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notesPatient"
              label=""
              fullWidth
              multiline
              minRows={10}
              variant="filled"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '16px', display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" color="primary" style={{ marginRight: '16px' }} onClick={handleSaveNote}>Save</Button>
            <Button variant="contained" color="secondary" onClick={handleCloseNotesDialog}>Cancel</Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
}

function GenerateQRCode(props) {
  const setShowSection = props.setShowSection;
  const idPatient = props.idPatient;
  const setIdPatien = props.setIdPatien;
  const namePatient = props.namePatient;

  const token = jwt.sign(
    {
      data: {
        idPatient: idPatient,
      },
    },
    "mysecretpassword"
  );

  console.log(token);

  const handleClickReturn = () => {
    setShowSection(0);
    setIdPatien(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          {namePatient}´s QR Code
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <QRCode
          value={`${FRONTEND}/#/secureCode/${token}`} />
      </Grid>
    </Grid>
  );
}

function Payments(props) {
  const classes = useStyles();
  const setShowSection = props.setShowSection;
  const idPatient = props.idPatient;
  const setIdPatien = props.setIdPatien;
  const namePatient = props.namePatient;
  const userData = props.userData;

  const [payments, setPayments] = useState([]);
  const [newPaymentDate, setNewPaymentDate] = useState("");

  const [
    {
      data: getPatientPaymentsData,
      loading: getPatientPaymentsLoading,
      error: getPatientPaymentsError,
    },
    executeGetPatientPayments,
  ] = useAxios(
    {
      url: `${SERVER}/patients/payments/${idPatient}`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: addPatientPaymentData,
      loading: addPatientPaymentLoading,
      error: addPatientPaymentError,
    },
    executeAddPatientPayment,
  ] = useAxios(
    {
      url: `${SERVER}/patients/addPayment`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: removePatientPaymentData,
      loading: removePatientPaymentLoading,
      error: removePatientPaymentError,
    },
    executeRemovePatientPayment,
  ] = useAxios(
    {
      url: `${SERVER}/patients/removePayment`,
      method: "DELETE",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (getPatientPaymentsData) {
      setPayments(getPatientPaymentsData);
    }
  }, [getPatientPaymentsData]);

  useEffect(() => {
    if (addPatientPaymentData) {
      executeGetPatientPayments();
      Swal.fire({
        icon: "success",
        title: "The payment date has been saved",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [addPatientPaymentData, executeGetPatientPayments]);

  useEffect(() => {
    if (removePatientPaymentData) {
      executeGetPatientPayments();
      Swal.fire({
        icon: "success",
        title: "The payment has been removed",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [removePatientPaymentData, executeGetPatientPayments]);

  if (
    getPatientPaymentsLoading ||
    addPatientPaymentLoading ||
    removePatientPaymentLoading
  ) {
    return <CircularProgress />;
  }

  if (
    getPatientPaymentsError ||
    addPatientPaymentError ||
    removePatientPaymentError
  ) {
    return <ErrorComponent />;
  }

  const handleClickReturn = () => {
    setShowSection(0);
    setIdPatien(0);
  };

  const handleChangeNewPaymentDate = (e) => {
    setNewPaymentDate(e.target.value);
  };

  const addNewPayment = () => {
    if (newPaymentDate === "") {
      Swal.fire({
        icon: "warning",
        title: "Select a valid date",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      executeAddPatientPayment({
        data: {
          idPatient: idPatient,
          paymentDate: newPaymentDate,
          nextPayment: moment(newPaymentDate)
            .add(1, "year")
            .format("YYYY-MM-DD"),
        },
      });
    }
  };

  const cancelPayment = (paymentId) => {
    Swal.fire({
      text: "Are you sure to remove the payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        executeRemovePatientPayment({
          data: {
            idPayment: paymentId,
          },
        });
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          {namePatient}´s Payments
        </Typography>
      </Grid>
      {userData.actionPermissions === 1 && (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField
              id="newPaymentDate"
              className={classes.textField}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Payment Date"
              value={newPaymentDate}
              onKeyPress={(e) => {
                keyValidation(e, 4);
              }}
              onChange={handleChangeNewPaymentDate}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ alignSelf: "center", textAlign: "center" }}
          >
            <Button color="primary" variant="contained" onClick={addNewPayment}>
              Add new payment
            </Button>
          </Grid>
        </Fragment>)}
      <Grid item xs={12}>
        <Typography variant="h6">Payments History</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="patient payments table">
            <TableHead style={{ background: "#fafafa" }}>
              <TableRow>
                <TableCell>
                  <strong>Payment Date</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Next Payment</strong>
                </TableCell>
                <TableCell align="right">
                  <SettingsIcon style={{ color: "#000000" }} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.length > 0 ? (
                payments.map((payment, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {moment(payment.paymentDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="right">
                      {moment(payment.nextPayment).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="right">
                      {userData.actionPermissions === 1 && (
                        <Tooltip title="Cancel Payment">
                          <IconButton onClick={() => cancelPayment(payment.id)}>
                            <ClearIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="subtitle1" align="center">
                      No payments made
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Patients;
