import React from "react";
import { Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import Logo from "../../assets/images/Logo.png";
import moment from "moment";

const PDFStyles = StyleSheet.create({
    pages: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        width: '150px',
        height: '100px'
    },
    rows: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '70%'
    },
    columns: {
        border: '1px solid black',
        padding: 5,
        marginRight: 5,
        marginLeft: 5,
        marginTop: 30
    },
    titles: {
        fontSize: '8px',
        fontWeight: 'bold'
    },
    info: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginTop: 10,
        borderBottom: '1px solid black',
        width: '100%'
    },
    totalRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    halfRow: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column'
    },
    rowsInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoTitles: {
        fontSize: '7px',
        fontWeight: 'bold',
        marginTop: 2
    },
});

export default function PDFPeopleInformation(props) {
    return (
        <Document>
            <Page size="A4" style={PDFStyles.pages}>
                <Image src={Logo} style={PDFStyles.logo} />
                <View style={PDFStyles.rows}>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>INFORMATION</Text>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{`${props?.information?.firstName} ${props?.information?.lastName}`}</Text>
                                <Text style={PDFStyles.infoTitles}>Name</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.phone ? props.information?.phone : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Phone</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.email ? props.information?.email : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Email</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.hotel ? props.information?.hotel : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Hotel</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.roomNumber ? props.information?.roomNumber : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Room Number</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.startDate ? moment(props.information?.startDate).format('DD/MM/YYYY') : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Start Date</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.information?.endDate ? moment(props.information?.endDate).format('DD/MM/YYYY') : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>End Date</Text>
                            </View>
                        </View>
                    </View>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>EMERGENCY CONTACTS</Text>
                        {props?.emergencyContacts.map((emergencyContact, index) => (
                            <View key={index} style={PDFStyles.rowsInfo}>
                                <View style={PDFStyles.halfRow}>
                                    <Text style={PDFStyles.info}>{`${emergencyContact.firstName} ${emergencyContact.lastName}`}</Text>
                                    <Text style={PDFStyles.infoTitles}>Name</Text>
                                </View>
                                <View style={PDFStyles.halfRow}>
                                    <Text style={PDFStyles.info}>{emergencyContact.phone ? emergencyContact.phone : 'No data'}</Text>
                                    <Text style={PDFStyles.infoTitles}>Phone</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    )
}