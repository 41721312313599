import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import QRCode from "react-qr-code";
import { FRONTEND } from "../../config";

export default function PeopleCodes(props) {
    const userData = props.userData;
    console.log(userData);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6">People Codes</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <QRCode value={`${FRONTEND}/#/peopleInformation`} />
            </Grid>
        </Grid>
    )
}
