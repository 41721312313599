import React from "react";
import { Document, Page, StyleSheet, Text, View, Image, Link } from '@react-pdf/renderer';
import Logo from "../../assets/images/Logo.png";
import { SERVER } from "../../config";
import NoImageFound from "../../assets/images/noImageFound.png";

const PDFStyles = StyleSheet.create({
    pages: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: '150px',
        height: '100px'
    },
    picture: {
        width: '100px',
        height: '100px'
    },
    rows: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    columns: {
        width: '70%',
        border: '1px solid black',
        padding: 5,
        marginRight: 5,
        marginLeft: 5,
        marginTop: 30
    },
    titles: {
        fontSize: '8px',
        fontWeight: 'bold'
    },
    info: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginTop: 10,
        borderBottom: '1px solid black',
        width: '100%'
    },
    links: {
        fontSize: '9px',
        fontWeight: 'bold',
        marginTop: 10,
        width: '100%'
    },
    totalRow: {
        width: '100%'
    },
    halfRow: {
        width: '45%'
    },
    thirdRow: {
        width: '30%'
    },
    rowsInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowsInfoColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    infoTitles: {
        fontSize: '7px',
        fontWeight: 'bold',
        marginTop: 2
    },
});

export default function PDFView(props) {
    return (
        <Document>
            <Page size="A4" style={PDFStyles.pages}>
                <Image src={Logo} style={PDFStyles.logo} />
                <Image src={props?.data?.picture
                    ? `${SERVER}/files/images/${props?.data?.picture}`
                    : NoImageFound} style={PDFStyles.picture} />
                <View style={PDFStyles.rows}>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>PATIENT INFORMATION</Text>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{`${props?.data?.firstName} ${props?.data?.lastName}`}</Text>
                                <Text style={PDFStyles.infoTitles}>Name</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.address ? props.data?.address : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Address</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.dateOfBirth ? props.data?.dateOfBirth : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Date Of Birth</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.phone ? props.data?.phone : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Phone</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.phone2 ? props.data?.phone2 : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Phone 2</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.email ? props.data?.email : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Email</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.gender ? props.data?.gender : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Gender</Text>
                            </View>
                        </View>
                    </View>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>PATIENT MEDICAL INFORMATION</Text>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.doctor ? props.data?.doctor : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Main Doctor</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.doctorsPhone ? props.data?.doctorsPhone : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Doctor Phone</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.hospital ? props.data?.hospital : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Main Hospital</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.hospitalsPhone ? props.data?.hospitalsPhone : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>Hospital Phone</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.bloodType ? props.data?.bloodType : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>bloodType</Text>
                            </View>
                            <View style={PDFStyles.halfRow}>
                                <Text style={PDFStyles.info}>{props.data?.allergies ? props.data?.allergies : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>allergies</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{props.data?.medicines ? props.data?.medicines : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>medicines</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{props.data?.chronicMedicalConditions ? props.data?.chronicMedicalConditions : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>chronicMedicalConditions</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{props.data?.medicalEquipment ? props.data?.medicalEquipment : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>medicalEquipment</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={PDFStyles.totalRow}>
                                <Text style={PDFStyles.info}>{props.data?.immunizationHistory ? props.data?.immunizationHistory : 'No data'}</Text>
                                <Text style={PDFStyles.infoTitles}>immunizationHistory</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={PDFStyles.pages}>
                <View style={PDFStyles.rows}>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>PATIENT EMERGENCY CONTACTS</Text>
                        {props.data?.emergencyContacts.map((emergencyContact, index) => (
                            <View key={index} style={PDFStyles.rowsInfo}>
                                <View style={PDFStyles.thirdRow}>
                                    <Text style={PDFStyles.info}>{`${emergencyContact.firstName} ${emergencyContact.lastName}`}</Text>
                                    <Text style={PDFStyles.infoTitles}>Name</Text>
                                </View>
                                <View style={{ width: '5%' }}></View>
                                <View style={PDFStyles.thirdRow}>
                                    <Text style={PDFStyles.info}>{emergencyContact.phone ? emergencyContact.phone : 'No data'}</Text>
                                    <Text style={PDFStyles.infoTitles}>Phone</Text>
                                </View>
                                <View style={{ width: '5%' }}></View>
                                <View style={PDFStyles.thirdRow}>
                                    <Text style={PDFStyles.info}>{emergencyContact.email ? emergencyContact.email : 'No data'}</Text>
                                    <Text style={PDFStyles.infoTitles}>Email</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={PDFStyles.columns}>
                        <Text style={PDFStyles.titles}>PATIENT FILES</Text>
                        <View style={PDFStyles.rowsInfoColumn}>
                            {props.data?.documents.map((document, index) => (
                                <View key={index} style={PDFStyles.rowsInfo}>
                                    <Link style={PDFStyles.links} src={`${SERVER}/files/docs/${document.document}`}>{`${document.document} ${document.description ? `(${document.description})` : ''}`}</Link>
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}