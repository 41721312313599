import React from "react";
import {
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";
import Register from "./components/register/register";
import Login from "./components/login/login";
import Visits from "./components/visits/visits";
import Menu from "./components/menu/menu";
import Patients from "./components/patients/patients";
import Doctors from "./components/doctors/doctors";
import Hospitals from "./components/hospitals/hospitals";
import Payments from "./components/payments/payments";
import EmergencyCodes from "./components/emergencyCodes/emergencyCodes";
import SecureCode from "./components/secureCode/secureCode";
import PeopleCodes from "./components/peopleCodes/peopleCodes";
import PeopleInformation from "./components/peopleInformation/peopleInformation";
import CodeReader from "./components/CodeReader/codeReader";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/codeReader">
          <CodeReader />
        </Route>
        <Route exact path="/">
          <Menu Component={Payments} />
        </Route>
        <Route exact path="/visits">
          <Menu Component={Visits} />
        </Route>
        <Route exact path="/patients">
          <Menu Component={Patients} />
        </Route>
        <Route exact path="/doctors">
          <Menu Component={Doctors} />
        </Route>
        <Route exact path="/hospitals">
          <Menu Component={Hospitals} />
        </Route>
        {/* <Route exact path="/payments">
          <Menu Component={Payments} />
        </Route> */}
        <Route exact path="/emergencyCodes">
          <Menu Component={EmergencyCodes} />
        </Route>
        <Route exact path="/peopleCodes">
          <Menu Component={PeopleCodes} />
        </Route>
        <Route exact path="/secureCode/:token?">
          <SecureCode />
        </Route>
        <Route exact path="/peopleInformation">
          <PeopleInformation />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
