import React, { useState, useEffect, forwardRef, Fragment } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Fab,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  PermContactCalendar as PermContactCalendarIcon,
  AttachFile as AttachFileIcon,
  CropFree as CropFreeIcon
} from "@material-ui/icons";
import { keyValidation, pasteValidation } from "../../helpers/inputHelpers";
import Logo from "../../assets/images/Logo.png";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import jwt from "jsonwebtoken";
import moment from "moment";
import { FRONTEND, SERVER } from "../../config";
import NoImageFound from "../../assets/images/noImageFound.png";
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import PDFView from "../componentsHelpers/PDFView";
import QRCode from "react-qr-code";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SecureCode() {
  let { token = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : '' } = useParams();
  const [idPatient, setIdPatient] = useState(0);
  const [secureCode, setSecureCode] = useState("");
  const [showSection, setShowSection] = useState(0);
  const [patientData, setPatientData] = useState([]);
  const [isPatient, setIsPatient] = useState(false);
  const [isMasterCode, setIsMasterCode] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  /* const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [openInfoNotesDialog, setOpenInfoNotesDialog] = useState(false); */
  const [openContactsDialog, setOpenContactsDialog] = useState(false);
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [openQRDialog, setOpenQRDialog] = useState(false);
  /* const [notes, setNotes] = useState([]); */
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [files, setFiles] = useState([]);
  const [pathPDF, setPathPDF] = useState(null);
  const [namePDF, setNamePDF] = useState("");
  const [isOnlyEmergency, setIsOnlyEmergency] = useState(false);

  const [
    {
      data: validateCodeData,
      loading: validateCodeLoading,
      error: validateCodeError,
    },
    executeValidateCode,
  ] = useAxios(
    {
      url: `${SERVER}/validateCode/`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: registerVisitData,
      loading: registerVisitLoading,
      error: registerVisitError,
    },
    executeRegisterVisit,
  ] = useAxios(
    {
      url: `${SERVER}/validateCode/registerVisit/`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: patientsEmergencyData,
      loading: patientsEmergencyLoading,
      error: patientsEmergencyError,
    },
    executePatientsEmergency,
  ] = useAxios(
    {
      url: `${SERVER}/validateCode/patientsEmergency`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    try {
      localStorage.setItem("userToken", token);
      const decodedToken = jwt.verify(token, "mysecretpassword");
      setIdPatient(decodedToken.data.idPatient);
      document.querySelector('#my-manifest-placeholder').setAttribute('href', `${SERVER}/files/manifests/manifest${decodedToken.data.idPatient}.json`);
      /* if ("geolocation" in navigator) {
        console.log("la geolocalización está disponible");
      } else {
        console.log("la geolocalización NO está disponible");
      } */
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      }, (error) => {
        // El segundo parámetro es la función de error
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log('The user denied permission for Geolocation.');
            // El usuario denegó el permiso para la Geolocalización.
            Swal.fire({
              icon: "warning",
              title: "You must first give location permissions to your device for alerts to work properly.",
              showConfirmButton: false,
            });
            break;
          case error.POSITION_UNAVAILABLE:
            console.log('The location is not available.');
            // La ubicación no está disponible.
            break;
          case error.TIMEOUT:
            console.log('The time to get the location has been exceeded.');
            // Se ha excedido el tiempo para obtener la ubicación.
            break;
          case error.UNKNOWN_ERROR:
            console.log('An unknown bug.');
            // Un error desconocido.
            break;
          default:
            console.log('An unknown bug (default).');
            // Un error desconocido.
            break;
        }
      }
      );

      if (localStorage.getItem("codeToken")) {
        const codeToken = localStorage.getItem("codeToken");
        const decodedCode = jwt.verify(codeToken, "secureCode");
        executeValidateCode({
          data: {
            idPatient: decodedToken.data.idPatient,
            secureCode: decodedCode.secureCode,
            currentDate: moment().format('YYYY-MM-DD')
          },
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err,
        showConfirmButton: true,
      });
      setShowSection(3);
      console.log(err);
    }
  }, [token, executeValidateCode]);

  useEffect(() => {

    async function getPDFPath(data, isNeedRegisterVisit, infoExistingCode) {
      const blobPDF = await pdf(<PDFView data={data} />).toBlob();
      /* const blobUrl = URL.createObjectURL(blobPDF); */
      setPathPDF(blobPDF);
      setNamePDF(`(${data.id})${data.firstName} ${data.lastName} info.pdf`);

      if (isNeedRegisterVisit) {
        const formData = new FormData();
        formData.append("idExistingCode", infoExistingCode.id);
        formData.append("isDoctor", infoExistingCode.isDoctor);
        formData.append("isEmergencyCode", infoExistingCode.isEmergencyCode);
        formData.append("isHospital", infoExistingCode.isHospital);
        formData.append("nameExistingCode", infoExistingCode.name);
        formData.append("idPatient", data.id);
        formData.append("patientFirstName", data.firstName);
        formData.append("patientLastName", data.lastName);
        formData.append("date", moment().format("YYYY-MM-DD"));
        formData.append("hour", moment().format("H:mm"));
        formData.append("namePDF", `(${data.id})${data.firstName} ${data.lastName} info.pdf`);
        formData.append("pathPDF", blobPDF);
        executeRegisterVisit({
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

    }

    if (validateCodeData) {
      if (validateCodeData.error) {
        Swal.fire({
          icon: "warning",
          title: validateCodeData.message,
          showConfirmButton: false,
          timer: 3000,
        });
        localStorage.removeItem('codeToken');
      } else {
        getPDFPath(validateCodeData.data[0], !validateCodeData.isPatient && !validateCodeData.isMasterCode && !validateCodeData.isOnlyEmergency, validateCodeData.infoExistingCode);
        setPatientData(validateCodeData.data[0]);
        /* setNotes(validateCodeData.data[0].notes); */
        setEmergencyContacts(validateCodeData.data[0].emergencyContacts);
        setFiles(validateCodeData.data[0].documents);
        setIsPatient(validateCodeData.isPatient);
        setIsMasterCode(validateCodeData.isMasterCode);
        setIsOnlyEmergency(validateCodeData.isOnlyEmergency)
        localStorage.setItem("codeToken", validateCodeData.codeToken);
        setSecureCode('');
        setShowSection(1);
      }
    }
  }, [validateCodeData, executeRegisterVisit]);

  useEffect(() => {
    if (registerVisitData) {
      if (registerVisitData.error) {
        Swal.fire({
          icon: "warning",
          title: registerVisitData.message,
          showConfirmButton: true,
          timer: 3000,
        });
      }
    }
  }, [registerVisitData]);

  useEffect(() => {
    if (patientsEmergencyData) {
      if (patientsEmergencyData.error) {
        Swal.fire({
          icon: "warning",
          title: patientsEmergencyData.message,
          showConfirmButton: true,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: patientsEmergencyData.message,
          showConfirmButton: true,
          timer: 3000,
        });
      }
    }
  }, [patientsEmergencyData]);

  useEffect(() => {
    if (idPatient !== 0) {
      const token = jwt.sign(
        {
          data: {
            idPatient: idPatient,
          },
        },
        "mysecretpassword"
      );

      console.log(token);
    }
  }, [idPatient])


  if (validateCodeLoading || patientsEmergencyLoading || registerVisitLoading) {
    <CircularProgress />;
  }

  if (validateCodeError || patientsEmergencyError || registerVisitError) {
    Swal.fire({
      icon: "error",
      title: "An error occurred on the server",
      showConfirmButton: true,
    });
  }

  const handleChangueSegureCode = (e) => {
    pasteValidation(e, 2);
    setSecureCode(e.target.value);
  };

  const handleClickSendSegureCode = () => {
    if (secureCode.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Enter a security code please",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      executeValidateCode({
        data: {
          idPatient: idPatient,
          secureCode: secureCode,
          currentDate: moment().format('YYYY-MM-DD')
        },
      });
    }
  };

  const patientEmergency = () => {
    /* navigator.permissions.query({ name: "geolocation" }).then((permissions) => {
      console.log(permissions.state);
      if (permissions.state !== "granted") {
        Swal.fire({
          icon: "warning",
          title: "You must first give location permissions to your device.",
          showConfirmButton: false,
          timer: 5000,
        });
        return;
      }
    }); */

    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      Swal.fire({
        title: "Are you sure to make an emergency call?",
        text: "An email and SMS with your location will be sent to the MTM staff.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f50057",
        cancelButtonColor: "#0274ca",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("idPatient", idPatient);
          formData.append("latitude", latitude ? latitude : position.coords.latitude);
          formData.append("longitude", longitude ? longitude : position.coords.longitude);
          formData.append("namePDF", namePDF);
          formData.append("pathPDF", pathPDF);
          executePatientsEmergency({
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
      });
    }, (error) => {
      let message = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log('The user denied permission for Geolocation.');
          message = 'You must first give location permissions to your device for alerts to work properly.';
          // El usuario denegó el permiso para la Geolocalización.
          break;
        case error.POSITION_UNAVAILABLE:
          console.log('The location is not available.');
          message = 'The location is not available.';
          // La ubicación no está disponible.
          break;
        case error.TIMEOUT:
          console.log('The time to get the location has been exceeded.');
          message = 'The time to get the location has been exceeded.';
          // Se ha excedido el tiempo para obtener la ubicación.
          break;
        case error.UNKNOWN_ERROR:
          console.log('An unknown bug.');
          message = 'The location is not available.';
          // Un error desconocido.
          break;
        default:
          console.log('An unknown bug (default).');
          message = 'The location is not available.';
          // Un error desconocido.
          break;
      }
      Swal.fire({
        icon: "warning",
        title: message,
        showConfirmButton: false,
        timer: 5000,
      });
    }
    );
  };

  /* const handleClickOpenNotesDialog = () => {
    setOpenNotesDialog(true);
  };

  const handleCloseNotesDialog = () => {
    setOpenNotesDialog(false);
  };

  const handleClickOpenInfoNotesDialog = () => {
    setOpenInfoNotesDialog(true);
  };

  const handleCloseInfoNotesDialog = () => {
    setOpenInfoNotesDialog(false);
  }; */

  const handleClickOpenContactsDialog = () => {
    setOpenContactsDialog(true);
  };

  const handleCloseContactsDialog = () => {
    setOpenContactsDialog(false);
  };

  const handleClickOpenFilesDialog = () => {
    setOpenFilesDialog(true);
  };

  const handleCloseFilesDialog = () => {
    setOpenFilesDialog(false);
  };

  const handleClickOpenQRDialog = () => {
    setOpenQRDialog(true);
  };

  const handleCloseQRDialog = () => {
    setOpenQRDialog(false);
  };

  const onLogOut = () => {
    localStorage.removeItem('codeToken');
    setShowSection(0);
  }

  return (
    <Grid container spacing={2} style={{ width: '100%' }}>
      {showSection === 1 && (
        <Grid item xs={12} style={{ textAlign: "end" }}>
          <Button color="secondary" variant="contained" onClick={onLogOut}>Exit</Button>
        </Grid>
      )}
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <img src={Logo} alt="logo" height={200} />
      </Grid>
      {showSection === 0 ? (
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Enter your security code
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <TextField
              id="secureCode"
              type="text"
              value={secureCode}
              inputProps={{
                maxLength: 5,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 2);
              }}
              onChange={handleChangueSegureCode}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSendSegureCode}
            >
              Send
            </Button>
          </Grid>
        </Fragment>
      ) : showSection === 1 ? (
        <Fragment>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img
              src={
                patientData.picture
                  ? `${SERVER}/files/images/${patientData.picture}`
                  : NoImageFound
              }
              alt={patientData.picture ? patientData.picture : "patient"}
              height="200"
              width="200"
            />
          </Grid>
          {((isPatient && patientData.role === 1) || isMasterCode || isOnlyEmergency) && (
            <Grid
              item
              xs={isOnlyEmergency ? 12 : 6}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              <Button
                variant="contained"
                color={"secondary"}
                onClick={patientEmergency}
              >
                EMERGENCY
              </Button>
            </Grid>
          )}
          {!isOnlyEmergency && (<Fragment>
            <Grid item xs={(isPatient && patientData.role === 1) || isMasterCode ? 3 : 6} style={{ textAlign: "center" }}>
              <Fab
                style={{ backgroundColor: "#0274c6", color: "#ffffff" }}
                aria-label="contacts"
                onClick={handleClickOpenContactsDialog}
              >
                <PermContactCalendarIcon />
              </Fab>
            </Grid>
            <Grid item xs={(isPatient && patientData.role === 1) || isMasterCode ? 3 : 6} style={{ textAlign: "center" }}>
              <Fab
                style={{ backgroundColor: "#41ab22", color: "#ffffff" }}
                aria-label="files"
                onClick={handleClickOpenFilesDialog}
              >
                <AttachFileIcon />
              </Fab>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <PDFDownloadLink style={{ textDecoration: 'none' }} document={<PDFView data={patientData} />} fileName={`(${patientData.id})${patientData.firstName} ${patientData.lastName} info.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Button variant="contained" color="primary">Download PDF</Button>)}
              </PDFDownloadLink>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <Fab
                aria-label="files"
                onClick={handleClickOpenQRDialog}
              >
                <CropFreeIcon />
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Patient´s Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Name"
                    secondary={`${patientData.firstName} ${patientData.lastName}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Gender"
                    secondary={`${patientData.gender ? patientData.gender : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Address"
                    secondary={`${patientData.address ? patientData.address : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Phone"
                    secondary={`${patientData.phone ? patientData.phone : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Phone 2"
                    secondary={`${patientData.phone2 ? patientData.phone2 : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Email"
                    secondary={`${patientData.email ? patientData.email : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Date Of Birth"
                    secondary={`${patientData.dateOfBirth
                      ? patientData.dateOfBirth
                      : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Main Doctor"
                    secondary={`${patientData.doctor
                      ? `${patientData.doctor} (${patientData.doctorsPhone})`
                      : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Main Hospital"
                    secondary={`${patientData.hospital
                      ? `${patientData.hospital} (${patientData.hospitalsPhone})`
                      : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Blood Type"
                    secondary={`${patientData.bloodType ? patientData.bloodType : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Medicines"
                    secondary={`${patientData.medicines ? patientData.medicines : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Chronic Medical Conditions"
                    secondary={`${patientData.chronicMedicalConditions
                      ? patientData.chronicMedicalConditions
                      : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Medical Equipment"
                    secondary={`${patientData.medicalEquipment
                      ? patientData.medicalEquipment
                      : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Allergies"
                    secondary={`${patientData.allergies ? patientData.allergies : "No data"
                      }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Immunization History"
                    secondary={`${patientData.immunizationHistory
                      ? patientData.immunizationHistory
                      : "No data"
                      }`}
                  />
                </ListItem>
              </List>
            </Grid>
          </Fragment>)}
        </Fragment>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Token Incorrecto
          </Typography>
        </Grid>
      )}
      <Dialog
        fullScreen
        open={openContactsDialog}
        onClose={handleCloseContactsDialog}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar style={{ backgroundColor: "#0274c6", color: "#ffffff" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseContactsDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography style={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Emergency Contacts
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {emergencyContacts.map((emergencyContact, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={`${emergencyContact.firstName} ${emergencyContact.lastName}`}
                secondary={`${emergencyContact.phone}`}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Dialog
        fullScreen
        open={openFilesDialog}
        onClose={handleCloseFilesDialog}
        TransitionComponent={Transition}
      >
        <AppBar
          style={{
            position: "relative",
            backgroundColor: "#41ab22",
            color: "#ffffff",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseFilesDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography style={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Files
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {files.map((file, index) => (
            <ListItem
              key={index}
              button
              divider
              onClick={() => {
                window.open(`${SERVER}/files/docs/${file.document}`);
              }}
            >
              <ListItemText
                primary={`${file.document}`}
                secondary={`${file.description ? file.description : "No Description"
                  }`}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Dialog
        fullScreen
        open={openQRDialog}
        onClose={handleCloseQRDialog}
        TransitionComponent={Transition}
      >
        <AppBar
          style={{
            position: "relative",
            backgroundColor: "#e0e0e0",
            color: "#000000",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseQRDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography style={{ ml: 2, flex: 1 }} variant="h6" component="div">
              My QR Code
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: '30px' }}>
          <QRCode value={`${FRONTEND}/#/secureCode/${token}`} />
        </Grid>
      </Dialog>
    </Grid>
  );
}
