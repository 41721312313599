import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  AppBar,
  CssBaseline,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  DateRange as DateRangeIcon,
  PeopleAlt as PeopleAltIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  LocalHospital as LocalHospitalIcon,
  /* MonetizationOn as MonetizationOnIcon, */
  VpnKey as VpnKeyIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Logo from "../../assets/images/Logo.png";
import useAxios from "axios-hooks";
import { SERVER } from "../../config";
import Swal from "sweetalert2";
import ProtectedRoute from "../componentsHelpers/protectedRoute";
import Payments from "../payments/payments";
import Visits from "../visits/visits";
import Patients from "../patients/patients";
import Doctors from "../doctors/doctors";
import Hospitals from "../hospitals/hospitals";
import EmergencyCodes from "../emergencyCodes/emergencyCodes";
import PeopleCodes from "../peopleCodes/peopleCodes";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  linksMenu: {
    textDecoration: "none",
    color: "#000000",
  },
  linksMenuItems: {
    "&:hover": {
      background: "#0274ca",
      color: "#ffffff",
    },
  },
  appBar: {
    background: "#0274ca",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  component: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
}));

function Menu(props) {
  const { window, Component } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeLogin, setActiveLogin] = useState(true);
  const [userData, setUserData] = useState(null);
  const itemsMenu = [
    {
      name: "Home",
      path: "/",
      icon: <HomeIcon style={{ color: "#000000" }} />,
      component: <Payments />,
      permissions: 1
    },
    {
      name: "Visits",
      path: "/visits",
      icon: <DateRangeIcon style={{ color: "#000000" }} />,
      component: <Visits userData={userData} />,
      permissions: 2
    },
    {
      name: "Patients",
      path: "/patients",
      icon: <PeopleAltIcon style={{ color: "#000000" }} />,
      component: <Patients userData={userData} />,
      permissions: 1
    },
    {
      name: "Doctors",
      path: "/doctors",
      icon: <SupervisedUserCircleIcon style={{ color: "#000000" }} />,
      component: <Doctors userData={userData} />,
      permissions: 1
    },
    {
      name: "Hospitals",
      path: "/hospitals",
      icon: <LocalHospitalIcon style={{ color: "#000000" }} />,
      component: <Hospitals userData={userData} />,
      permissions: 1
    },
    /* {
      name: "Payments",
      path: "/payments",
      icon: <MonetizationOnIcon style={{ color: "#000000" }} />,
    }, */
    {
      name: "Emergency Codes",
      path: "/emergencyCodes",
      icon: <VpnKeyIcon style={{ color: "#000000" }} />,
      component: <EmergencyCodes userData={userData} />,
      permissions: 1
    },
    {
      name: "People Codes",
      path: "/peopleCodes",
      icon: <VpnKeyIcon style={{ color: "#000000" }} />,
      component: <PeopleCodes userData={userData} />,
      permissions: 1
    },
  ];

  const [
    {
      data: checkLoginData,
      loading: checkLoginLoading,
      error: checkLoginError,
    },
    executeCheckLogin,
  ] = useAxios(
    {
      url: `${SERVER}/login/check`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      const token = sessionStorage.getItem("token");
      executeCheckLogin({
        data: {
          token: token,
        },
      });
    } else {
      setActiveLogin(false);
    }
  }, [executeCheckLogin]);

  useEffect(() => {
    if (checkLoginData) {
      setActiveLogin(checkLoginData.validateLogin);
      setUserData(checkLoginData.validateLogin ? checkLoginData.userData[0] : null);
    }
  }, [checkLoginData]);

  if (checkLoginLoading) {
    return <CircularProgress />;
  }

  if (checkLoginError) {
    Swal.fire({
      icon: "error",
      title: "An error occurred on the server",
      showConfirmButton: true,
    });
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logOut = () => {
    Swal.fire({
      title: "Are you sure to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("token");
        setActiveLogin(false);
      }
    });
  };

  const drawer = !activeLogin ? (
    <Redirect to="/login" />
  ) : (
    <div style={{ minHeight: "100%" }}>
      <Grid container justify="center">
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Link to="/">
            <img src={Logo} alt="logo" height={200} />
          </Link>
        </Grid>
      </Grid>
      <List>
        {itemsMenu.filter(item => item.permissions >= userData?.permissions).map((item, index) => (
          <Link className={classes.linksMenu} to={item.path} key={index}>
            <ListItem button className={classes.linksMenuItems}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Grid
        container
        justify="center"
        style={{ position: "absolute", bottom: "15px" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button color="secondary" variant="contained" onClick={logOut}>
            Log Out
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Medical Tourism Mazatlán
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.component}>
            <ProtectedRoute userData={userData} itemsMenu={itemsMenu}>
              <Component userData={userData} />
            </ProtectedRoute>
          </div>
        </div>
      </main>
    </div>
  );
}

Menu.propTypes = {
  window: PropTypes.func,
};

export default Menu;
