import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Collapse,
  Box,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Clear as ClearIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import { keyValidation, pasteValidation } from "../../helpers/inputHelpers";
import { SERVER } from "../../config";
import ErrorComponent from "../componentsHelpers/error.js";

function createData(
  id,
  name,
  address,
  phone,
  email,
  startWorkDay,
  endWorkDay,
  fullDay,
  status,
  secureCode
) {
  return {
    id,
    name,
    address,
    phone,
    email,
    startWorkDay,
    endWorkDay,
    fullDay,
    status,
    secureCode
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    ordering: true,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
    ordering: true,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    ordering: true,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    ordering: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: <SettingsIcon />,
    ordering: false,
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#fafafa" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.ordering ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <strong>{headCell.label}</strong>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textField: {
    width: "100%",
  },
}));

export default function Hospitals(props) {
  const classes = useStyles();
  const userData = props.userData;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [hospitals, setHospitals] = useState([]);
  const [searchHospitals, setSearchHospitals] = useState("");
  const [rows, setRows] = useState([]);
  const [openCollapseTable, setOpenCollapseTable] = useState(0);
  const [showSection, setShowSection] = useState(0);
  const [idHospital, setIdHospital] = useState(0);

  const [
    {
      data: getHospitalsData,
      loading: getHospitalsLoading,
      error: getHospitalsError,
    },
    executeGetHospitals,
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (getHospitalsData) {
      setHospitals(getHospitalsData);
      let rows = [];
      getHospitalsData.map((hospital) => {
        return rows.push(
          createData(
            hospital.id,
            hospital.name,
            hospital.address,
            hospital.phone,
            hospital.email,
            hospital.startWorkDay,
            hospital.endWorkDay,
            hospital.fullDay,
            hospital.status,
            hospital.secureCode
          )
        );
      });
    }
  }, [getHospitalsData]);

  useEffect(() => {
    const filterRows = () => {
      let rows = [];
      for (let x = 0; x < hospitals.length; x++) {
        if (
          hospitals[x].name
            .toLowerCase()
            .indexOf(searchHospitals.trim().toLowerCase()) !== -1 ||
          hospitals[x].address
            .toLowerCase()
            .indexOf(searchHospitals.trim().toLowerCase()) !== -1 ||
          hospitals[x].phone
            .toLowerCase()
            .indexOf(searchHospitals.trim().toLowerCase()) !== -1 ||
          hospitals[x].email
            .toLowerCase()
            .indexOf(searchHospitals.trim().toLowerCase()) !== -1
        ) {
          rows.push(
            createData(
              hospitals[x].id,
              hospitals[x].name,
              hospitals[x].address,
              hospitals[x].phone,
              hospitals[x].email,
              hospitals[x].startWorkDay,
              hospitals[x].endWorkDay,
              hospitals[x].fullDay,
              hospitals[x].status,
              hospitals[x].secureCode
            )
          );
        }
      }
      return rows;
    };

    setRows(searchHospitals.trim() === "" ? hospitals : filterRows());
  }, [searchHospitals, hospitals]);

  if (getHospitalsLoading) {
    return <CircularProgress />;
  }

  if (getHospitalsError) {
    return <ErrorComponent />;
  }

  const handleChangueSearchHospitals = (e) => {
    setSearchHospitals(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickGoToSaveHospital = () => {
    setShowSection(1);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Hospitals</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {showSection === 0 ? (
        <Fragment>
          <Grid item xs={12} md={userData.actionPermissions === 1 ? 6 : 12}>
            <TextField
              id="searchHospitals"
              type="text"
              label="Search"
              value={searchHospitals}
              onChange={handleChangueSearchHospitals}
            />
          </Grid>
          {userData.actionPermissions === 1 && (
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ background: "#0274ca", color: "white", float: "right" }}
                onClick={handleClickGoToSaveHospital}
              >
                Add
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={3}>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.length > 0 ? (
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <Fragment key={index}>
                              <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell padding="checkbox">
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() =>
                                      setOpenCollapseTable(
                                        row.id === openCollapseTable
                                          ? 0
                                          : row.id
                                      )
                                    }
                                  >
                                    {row.id === openCollapseTable ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.address}
                                </TableCell>
                                <TableCell align="right">{row.phone}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell align="right">
                                  {userData.actionPermissions === 1 && (
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          handleClickGoToSaveHospital();
                                          setIdHospital(row.id);
                                        }}
                                      >
                                        <EditIcon style={{ color: "#0274ca" }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={6}
                                >
                                  <Collapse
                                    in={row.id === openCollapseTable}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin={1}>
                                      <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                      >
                                        <strong>Details</strong>
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead
                                          style={{ background: "#fafafa" }}
                                        >
                                          <TableRow>
                                            <TableCell>
                                              <strong>Schedule</strong>
                                            </TableCell>
                                            <TableCell>
                                              <strong>Secure Code</strong>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              {row.fullDay
                                                ? "24 Hours"
                                                : `${row.startWorkDay} - ${row.endWorkDay}`}
                                            </TableCell>
                                            <TableCell>
                                              {row.secureCode}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          <Typography variant="subtitle1">
                            No hospitals were found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Fragment>
      ) : showSection === 1 ? (
        <SaveHospitalInformation
          setShowSection={setShowSection}
          executeGetHospitals={executeGetHospitals}
          idHospital={idHospital}
          setIdHospital={setIdHospital}
        />
      ) : null}
    </Grid>
  );
}

function SaveHospitalInformation(props) {
  const classes = useStyles();

  const setShowSection = props.setShowSection;
  const executeGetHospitals = props.executeGetHospitals;
  const idHospital = props.idHospital;
  const setIdHospital = props.setIdHospital;

  const [infoHospital, setInfoHospital] = useState({
    id: 0,
    name: "",
    address: "",
    phone: "",
    email: "",
    startWorkDay: "",
    endWorkDay: "",
    fullDay: false,
  });
  const [validateForm, setValidateForm] = useState(false);

  const [
    {
      data: saveHospitalData,
      loading: saveHospitalLoading,
      error: saveHospitalError,
    },
    executeSaveHospital,
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/save`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: getHospitalData,
      loading: getHospitalLoading,
      error: getHospitalError,
    },
    executeGetPatient,
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/${idHospital}`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (idHospital !== 0) {
      executeGetPatient();
    }
  }, [idHospital, executeGetPatient]);

  useEffect(() => {
    if (saveHospitalData) {
      executeGetHospitals();
      setShowSection(0);
      setInfoHospital({
        id: 0,
        name: "",
        address: "",
        phone: "",
        email: "",
        startWorkDay: "",
        endWorkDay: "",
        fullDay: false,
      });
      setValidateForm(false);
      Swal.fire({
        icon: "success",
        title: "The hospital information has been saved",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [saveHospitalData, executeGetHospitals, setShowSection]);

  useEffect(() => {
    if (getHospitalData) {
      setInfoHospital({
        id: getHospitalData[0].id ? getHospitalData[0].id : 0,
        name: getHospitalData[0].name,
        address: getHospitalData[0].address ? getHospitalData[0].address : "",
        phone: getHospitalData[0].phone ? getHospitalData[0].phone : "",
        email: getHospitalData[0].email ? getHospitalData[0].email : "",
        startWorkDay: getHospitalData[0].startWorkDay
          ? getHospitalData[0].startWorkDay
          : "",
        endWorkDay: getHospitalData[0].endWorkDay
          ? getHospitalData[0].endWorkDay
          : "",
        fullDay: getHospitalData[0].fullDay === 1 ? true : false,
      });
    }
  }, [getHospitalData]);

  if (saveHospitalLoading || getHospitalLoading) {
    return <CircularProgress />;
  }

  if (saveHospitalError || getHospitalError) {
    return <ErrorComponent />;
  }

  const handleClickReturn = () => {
    setShowSection(0);
    setIdHospital(0);
    setInfoHospital({
      id: 0,
      name: "",
      address: "",
      phone: "",
      email: "",
      startWorkDay: "",
      endWorkDay: "",
      fullDay: false,
    });
    setValidateForm(false);
  };

  const handleChangeInputsSaveHospitalInformation = (e) => {
    if (e.target.id === "name") {
      pasteValidation(e, 1);
    } else if (e.target.id === "address") {
      pasteValidation(e, 3);
    } else if (e.target.id === "phone") {
      pasteValidation(e, 2);
    } else if (e.target.id === "email") {
      pasteValidation(e, 4);
    }

    setInfoHospital({
      ...infoHospital,
      [e.target.id]:
        e.target.id !== "fullDay" ? e.target.value : e.target.checked,
    });
  };

  const handleClickSaveHospitalInformation = () => {
    const {
      id,
      name,
      address,
      phone,
      email,
      startWorkDay,
      endWorkDay,
      fullDay,
    } = infoHospital;

    if (
      name.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Fill in all the required fields",
        showConfirmButton: false,
        timer: 3000,
      });
      setValidateForm(true);
    } else {
      setValidateForm(false);
      executeSaveHospital({
        data: {
          id: id,
          name: name.trim(),
          address: address.trim(),
          phone: phone.trim(),
          email: email.trim(),
          startWorkDay: startWorkDay,
          endWorkDay: endWorkDay,
          fullDay: fullDay,
        },
      });
    }
  };

  const handleClickCleanHospitalForm = () => {
    setInfoHospital({
      id: 0,
      name: "",
      address: "",
      phone: "",
      email: "",
      startWorkDay: "",
      endWorkDay: "",
      fullDay: false,
    });
  };

  return (
    <Paper elevation={3} style={{ padding: 15 }}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            <Tooltip title="Back">
              <IconButton onClick={handleClickReturn}>
                <ArrowBackIcon style={{ color: "#0274ca" }} />
              </IconButton>
            </Tooltip>
            Save Hospital Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Tooltip title="Clean" style={{ float: "right" }}>
            <IconButton onClick={handleClickCleanHospitalForm}>
              <ClearIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save" style={{ float: "right" }}>
            <IconButton onClick={handleClickSaveHospitalInformation}>
              <SaveIcon style={{ color: "#000000" }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="name"
            error={infoHospital.name.trim() === "" && validateForm}
            helperText={
              infoHospital.name.trim() === "" && validateForm
                ? "This field is require"
                : ""
            }
            className={classes.textField}
            type="text"
            label="Name"
            required
            value={infoHospital.name}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 1);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="address"
            className={classes.textField}
            type="text"
            label="Address"
            value={infoHospital.address}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="phone"
            className={classes.textField}
            type="text"
            label="Phone"
            inputProps={{
              maxLength: 15,
            }}
            value={infoHospital.phone}
            onKeyPress={(e) => {
              keyValidation(e, 2);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="email"
            className={classes.textField}
            type="text"
            label="Email"
            inputProps={{
              maxLength: 100,
            }}
            value={infoHospital.email}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="startWorkDay"
            className={classes.textField}
            type="time"
            label="Start Work Day"
            disabled={infoHospital.fullDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            value={infoHospital.startWorkDay}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="endWorkDay"
            className={classes.textField}
            type="time"
            label="End Work Day"
            disabled={infoHospital.fullDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            value={infoHospital.endWorkDay}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveHospitalInformation}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                id="fullDay"
                checked={infoHospital.fullDay}
                onChange={handleChangeInputsSaveHospitalInformation}
                name="checkeFT"
                color="primary"
              />
            }
            label="Full Time?"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
