import React, { useState, useEffect, Fragment, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Card,
  CardContent,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import { SERVER } from "../../config";
import ErrorComponent from "../componentsHelpers/error.js";
import socket from "../../socket";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

function Visits({ userData }) {
  const [visits, setVisits] = useState([]);
  const [showSection, setShowSection] = useState(0);
  const [dateSelected, setDateSelected] = useState("");
  const calendarComponentRef = useRef(null);

  const [
    { data: getVisitsData, loading: getVisitsLoading, error: getVisitsError },
    executeGetVisits,
  ] = useAxios(
    {
      url: `${SERVER}/visits/calendar/${userData?.idDoctor ? userData?.idDoctor : 0}/${userData?.idHospital ? userData?.idHospital : 0}`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true
    }
  );

  useEffect(() => {
    if (userData) {
      executeGetVisits();
    }
  }, [userData, executeGetVisits]);

  useEffect(() => {
    if (getVisitsData) {
      setVisits(getVisitsData);
    }
  }, [getVisitsData]);

  useEffect(() => {
    if(calendarComponentRef.current && dateSelected) {
      let calendarApi = calendarComponentRef.current.getApi();
      calendarApi.gotoDate(dateSelected);
      setDateSelected("");
    }
  }, [showSection, dateSelected]);

  if (getVisitsLoading) {
    return <CircularProgress />;
  }
  if (getVisitsError) {
    return <ErrorComponent />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Visits</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {showSection === 0 ? (
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Select a day to view all patient visits.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FullCalendar
              ref={calendarComponentRef}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth",
              }}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
              /* selectable
          selectMirror */
              events={visits}
              eventColor="#0274ca"
              dateClick={(info) => {
                const numVisits = visits.filter(
                  (visit) => visit.date === info.dateStr
                ).length;
                if (numVisits === 0) {
                  Swal.fire({
                    icon: "warning",
                    title: "No visits on date " + info.dateStr,
                    showConfirmButton: false,
                    timer: 3000,
                  });
                } else {
                  setShowSection(1);
                  setDateSelected(info.dateStr);
                }
              }}
              eventClick={(eventInfo) => {
                const numVisits = visits.filter(
                  (visit) => visit.date === eventInfo.event.startStr
                ).length;
                if (numVisits === 0) {
                  Swal.fire({
                    icon: "warning",
                    title: "No visits on date " + eventInfo.event.startStr,
                    showConfirmButton: false,
                    timer: 3000,
                  });
                } else {
                  setShowSection(1);
                  setDateSelected(eventInfo.event.startStr);
                }
              }}
            /* select={(selectionInfo) => {
          console.log(`${selectionInfo.startStr} - ${selectionInfo.endStr}`);
        }} */
            />
          </Grid>
        </Fragment>
      ) : (
        <Grid item xs={12}>
          <VisitsPerDay
            setShowSection={setShowSection}
            dateSelected={dateSelected}
            userData={userData}
          />
        </Grid>
      )}
    </Grid>
  );
}

function VisitsPerDay(props) {
  const classes = useStyles();
  const setShowSection = props.setShowSection;
  const dateSelected = props.dateSelected;
  const userData = props.userData;

  const [infoVisits, setInfoVisits] = useState([]);
  const [filterInfoVisits, setFilterInfoVisits] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(0);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(0);
  const [executeVisitsPerDate, setExecuteVisitsPerDate] = useState(true);

  const [
    {
      data: getVisitPerDayData,
      loading: getVisitPerDayLoading,
      error: getVisitPerDayError,
    },
    executeGetVisitPerDayData,
  ] = useAxios(
    {
      url: `${SERVER}/visits/${dateSelected}/${userData?.idDoctor ? userData?.idDoctor : 0}/${userData?.idHospital ? userData?.idHospital : 0}`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true
    }
  );

  const [
    {
      data: getActivesDoctorsData,
      loading: getActivesDoctorsLoading,
      error: getActivesDoctorsError,
    },
    executeGetActivesDoctorsData,
  ] = useAxios(
    {
      url: `${SERVER}/doctors/actives`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true
    }
  );

  const [
    {
      data: getActivesHospitalsData,
      loading: getActivesHospitalsLoading,
      error: getActivesHospitalsError,
    },
    executeGetActivesHospitalsData,
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/actives`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true
    }
  );

  useEffect(() => {
    socket.connect();
    socket.on('server:visits', () => {
      setExecuteVisitsPerDate(true);
    })
    return () => {
      socket.disconnect();
    }
  }, []);

  useEffect(() => {
    if (executeVisitsPerDate && dateSelected && userData) {
      executeGetVisitPerDayData();
      setExecuteVisitsPerDate(false);//todo ver la manera en la que esta api solo se ejecute cuando traiga la fecha bien sin ejecutar con la fecha anteriormente seleccionada
      if (!userData?.idDoctor && !userData?.idHospital) {
        executeGetActivesDoctorsData();
        executeGetActivesHospitalsData();
      }
    }
  }, [executeVisitsPerDate, dateSelected, userData, executeGetVisitPerDayData, executeGetActivesDoctorsData, executeGetActivesHospitalsData]);

  useEffect(() => {
    if (getVisitPerDayData) {
      setInfoVisits(getVisitPerDayData);
      setFilterInfoVisits(getVisitPerDayData);
    }
  }, [getVisitPerDayData]);

  useEffect(() => {
    if (getActivesDoctorsData) {
      setDoctors(getActivesDoctorsData);
    }
  }, [getActivesDoctorsData]);

  useEffect(() => {
    if (getActivesHospitalsData) {
      setHospitals(getActivesHospitalsData);
    }
  }, [getActivesHospitalsData]);

  useEffect(() => {
    let newInfoVisits = [];
    newInfoVisits = infoVisits.filter(
      (infoVisit) =>
        (infoVisit.idDoctor === selectedDoctor || selectedDoctor === 0) &&
        (infoVisit.idHospital === selectedHospital || selectedHospital === 0)
    );
    setFilterInfoVisits(newInfoVisits);
  }, [infoVisits, selectedDoctor, selectedHospital]);

  if (
    getVisitPerDayLoading ||
    getActivesDoctorsLoading ||
    getActivesHospitalsLoading
  ) {
    return <CircularProgress />;
  }
  if (
    getVisitPerDayError ||
    getActivesDoctorsError ||
    getActivesHospitalsError
  ) {
    return <ErrorComponent />;
  }

  const handleClickReturn = () => {
    setShowSection(0);
  };

  const handleChangeSelectedDoctor = (e) => {
    setSelectedDoctor(parseInt(e.target.value));
  };

  const handleChangeSelectedHospital = (e) => {
    setSelectedHospital(parseInt(e.target.value));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          {`Visits of the day ${dateSelected}`}
        </Typography>
      </Grid>
      {!userData?.idDoctor && !userData?.idHospital && (
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={5}>
              <TextField
                id="doctors"
                className={classes.textField}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Doctors"
                value={selectedDoctor}
                onChange={handleChangeSelectedDoctor}
              >
                <option value={0}>Any Doctor</option>
                {doctors.map((doctor, index) => (
                  <option
                    key={index}
                    value={doctor.id}
                  >{`${doctor.firstName} ${doctor.lastName}`}</option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="hospitals"
                className={classes.textField}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Hospitals"
                value={selectedHospital}
                onChange={handleChangeSelectedHospital}
              >
                <option value={0}>Any Hospital</option>
                {hospitals.map((hospital, index) => (
                  <option
                    key={index}
                    value={hospital.id}
                  >{`${hospital.name}`}</option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {filterInfoVisits.length > 0 ? (
            filterInfoVisits.map((infoVisit, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {`${infoVisit.hour.substr(0, 5)}`}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {`${infoVisit.patient} visited ${infoVisit.hospital
                        ? infoVisit.hospital
                        : infoVisit.doctor
                        }`}
                    </Typography>
                    {/* <Typography variant="body2">
                      {infoVisit.patient}
                    </Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    No visit record found
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Visits;
