import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Collapse,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Clear as ClearIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import { keyValidation, pasteValidation } from "../../helpers/inputHelpers";
import { SERVER } from "../../config";
import ErrorComponent from "../componentsHelpers/error.js";

function createData(
  id,
  firstName,
  lastName,
  address,
  phone,
  email,
  startWorkDay,
  endWorkDay,
  fullDay,
  status,
  secureCode
) {
  return {
    id,
    firstName,
    lastName,
    address,
    phone,
    email,
    startWorkDay,
    endWorkDay,
    fullDay,
    status,
    secureCode
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    ordering: true,
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
    ordering: true,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
    ordering: true,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    ordering: true,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    ordering: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: <SettingsIcon />,
    ordering: false,
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#fafafa" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.ordering ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <strong>{headCell.label}</strong>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textField: {
    width: "100%",
  },
}));

function Doctors(props) {
  const classes = useStyles();
  const userData = props.userData;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [doctors, setDoctors] = useState([]);
  const [searchDoctors, setSearchDoctors] = useState("");
  const [rows, setRows] = useState([]);
  const [openCollapseTable, setOpenCollapseTable] = useState(0);
  const [showSection, setShowSection] = useState(0);
  const [idDoctor, setIdDoctor] = useState(0);

  const [
    {
      data: getDoctorsData,
      loading: getDoctorsLoading,
      error: getDoctorsError,
    },
    executeGetDoctors,
  ] = useAxios(
    {
      url: `${SERVER}/doctors/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: deleteDoctorData,
      loading: deleteDoctorLoading,
      error: deleteDoctorError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/doctors/delete`,
      method: "DELETE",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (getDoctorsData) {
      setDoctors(getDoctorsData);
      let rows = [];
      getDoctorsData.map((patient) => {
        return rows.push(
          createData(
            patient.id,
            patient.firstName,
            patient.lastName,
            patient.address,
            patient.phone,
            patient.email,
            patient.startWorkDay,
            patient.endWorkDay,
            patient.fullDay,
            patient.status,
            patient.secureCode
          )
        );
      });
    }
  }, [getDoctorsData]);

  useEffect(() => {
    if (deleteDoctorData) {
      executeGetDoctors();
      Swal.fire({
        icon: "success",
        title: "The doctor has been deleted",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [deleteDoctorData, executeGetDoctors]);

  useEffect(() => {
    const filterRows = () => {
      let rows = [];
      for (let x = 0; x < doctors.length; x++) {
        if (
          doctors[x].firstName
            .toLowerCase()
            .indexOf(searchDoctors.trim().toLowerCase()) !== -1 ||
          doctors[x].lastName
            .toLowerCase()
            .indexOf(searchDoctors.trim().toLowerCase()) !== -1 ||
          doctors[x].address
            .toLowerCase()
            .indexOf(searchDoctors.trim().toLowerCase()) !== -1 ||
          doctors[x].phone
            .toLowerCase()
            .indexOf(searchDoctors.trim().toLowerCase()) !== -1 ||
          doctors[x].email
            .toLowerCase()
            .indexOf(searchDoctors.trim().toLowerCase()) !== -1
        ) {
          rows.push(
            createData(
              doctors[x].id,
              doctors[x].firstName,
              doctors[x].lastName,
              doctors[x].address,
              doctors[x].phone,
              doctors[x].email,
              doctors[x].startWorkDay,
              doctors[x].endWorkDay,
              doctors[x].fullDay,
              doctors[x].status,
              doctors[x].secureCode
            )
          );
        }
      }
      return rows;
    };

    setRows(searchDoctors.trim() === "" ? doctors : filterRows());
  }, [searchDoctors, doctors]);

  if (getDoctorsLoading || deleteDoctorLoading) {
    return <CircularProgress />;
  }

  if (getDoctorsError || deleteDoctorError) {
    return <ErrorComponent />;
  }

  const handleChangueSearchDoctor = (e) => {
    setSearchDoctors(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickGoToSaveDoctorInformation = () => {
    setShowSection(1);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Doctors</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {showSection === 0 ? (
        <Fragment>
          <Grid item xs={12} md={userData.actionPermissions === 1 ? 6 : 12}>
            <TextField
              id="searchDoctors"
              type="text"
              label="Search"
              value={searchDoctors}
              onChange={handleChangueSearchDoctor}
            />
          </Grid>
          {userData.actionPermissions === 1 && (
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ background: "#0274ca", color: "white", float: "right" }}
                onClick={handleClickGoToSaveDoctorInformation}
              >
                Add
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={3}>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows.length > 0 ? (
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <Fragment key={index}>
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                <TableCell padding="checkbox">
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() =>
                                      setOpenCollapseTable(
                                        row.id === openCollapseTable
                                          ? 0
                                          : row.id
                                      )
                                    }
                                  >
                                    {row.id === openCollapseTable ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.firstName}
                                </TableCell>
                                <TableCell align="right">
                                  {row.lastName}
                                </TableCell>
                                <TableCell align="right">
                                  {row.address}
                                </TableCell>
                                <TableCell align="right">{row.phone}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell align="right">
                                  {userData.actionPermissions === 1 && (
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          handleClickGoToSaveDoctorInformation();
                                          setIdDoctor(row.id);
                                        }}
                                      >
                                        <EditIcon style={{ color: "#0274ca" }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={7}
                                >
                                  <Collapse
                                    in={row.id === openCollapseTable}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin={1}>
                                      <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                      >
                                        <strong>Details</strong>
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead style={{ background: "#fafafa" }}>
                                          <TableRow>
                                            <TableCell><strong>Schedule</strong></TableCell>
                                            <TableCell><strong>Secure Code</strong></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              {row.fullDay
                                                ? "24 Hours"
                                                : `${row.startWorkDay} - ${row.endWorkDay}`}
                                            </TableCell>
                                            <TableCell>
                                              {row.secureCode}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No doctors were found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Fragment>
      ) : showSection === 1 ? (
        <SaveDoctorInformation
          setShowSection={setShowSection}
          executeGetDoctors={executeGetDoctors}
          idDoctor={idDoctor}
          setIdDoctor={setIdDoctor}
        />
      ) : showSection === 2 ? (
        <MedicalHistory
          setShowSection={setShowSection}
          idDoctor={idDoctor}
          setIdDoctor={setIdDoctor}
        />
      ) : null}
    </Grid>
  );
}

function SaveDoctorInformation(props) {
  const classes = useStyles();

  const setShowSection = props.setShowSection;
  const executeGetDoctors = props.executeGetDoctors;
  const idDoctor = props.idDoctor;
  const setIdDoctor = props.setIdDoctor;

  const [infoDoctor, setInfoDoctor] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
    email: "",
    startWorkDay: "",
    endWorkDay: "",
    fullDay: false,
  });
  const [validateForm, setValidateForm] = useState(false);

  const [
    {
      data: saveDoctorData,
      loading: saveDoctorLoading,
      error: saveDoctorError,
    },
    executeSaveDoctor,
  ] = useAxios(
    {
      url: `${SERVER}/doctors/save`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    { data: getDoctorData, loading: getDoctorLoading, error: getDoctorError },
    executeGetDoctor,
  ] = useAxios(
    {
      url: `${SERVER}/doctors/${idDoctor}`,
      method: "GET",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (idDoctor !== 0) {
      executeGetDoctor();
    }
  }, [idDoctor, executeGetDoctor]);

  useEffect(() => {
    if (saveDoctorData) {
      executeGetDoctors();
      setShowSection(0);
      setInfoDoctor({
        id: 0,
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        email: "",
        startWorkDay: "",
        endWorkDay: "",
        fullDay: false,
      });
      setValidateForm(false);
      Swal.fire({
        icon: "success",
        title: "The doctor information has been saved",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [saveDoctorData, executeGetDoctors, setShowSection]);

  useEffect(() => {
    if (getDoctorData) {
      setInfoDoctor({
        id: getDoctorData[0].id ? getDoctorData[0].id : 0,
        firstName: getDoctorData[0].firstName,
        lastName: getDoctorData[0].lastName,
        address: getDoctorData[0].address,
        phone: getDoctorData[0].phone,
        email: getDoctorData[0].email,
        startWorkDay: getDoctorData[0].startWorkDay,
        endWorkDay: getDoctorData[0].endWorkDay,
        fullDay: getDoctorData[0].fullDay === 1 ? true : false,
      });
    }
  }, [getDoctorData]);

  if (saveDoctorLoading || getDoctorLoading) {
    return <CircularProgress />;
  }

  if (saveDoctorError || getDoctorError) {
    return <ErrorComponent />;
  }

  const handleClickReturn = () => {
    setShowSection(0);
    setIdDoctor(0);
    setInfoDoctor({
      id: 0,
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      startWorkDay: "",
      endWorkDay: "",
      fullDay: false,
    });
    setValidateForm(false);
  };

  const handleChangeInputsSaveDoctorInformation = (e) => {
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      pasteValidation(e, 1);
    } else if (e.target.id === "address") {
      pasteValidation(e, 3);
    } else if (e.target.id === "phone") {
      pasteValidation(e, 2);
    } else if (e.target.id === "email") {
      pasteValidation(e, 4);
    }

    setInfoDoctor({
      ...infoDoctor,
      [e.target.id]: e.target.id !== "fullDay" ? e.target.value : e.target.checked,
    });
  };

  const handleClickSaveDoctor = () => {
    const {
      id,
      firstName,
      lastName,
      address,
      phone,
      email,
      startWorkDay,
      endWorkDay,
      fullDay,
    } = infoDoctor;
    if (
      firstName.trim() === "" ||
      lastName.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Fill in all the required fields",
        showConfirmButton: false,
        timer: 3000,
      });
      setValidateForm(true);
    } else {
      setValidateForm(false);
      executeSaveDoctor({
        data: {
          id: id,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          address: address.trim(),
          phone: phone.trim(),
          email: email.trim(),
          startWorkDay: startWorkDay,
          endWorkDay: endWorkDay,
          fullDay: fullDay,
        },
      });
    }
  };

  const handleClickCleanDoctorForm = () => {
    setInfoDoctor({
      id: 0,
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      startWorkDay: "",
      endWorkDay: "",
      fullDay: false,
    });
  };

  return (
    <Paper elevation={3} style={{ padding: 15 }}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            <Tooltip title="Back">
              <IconButton onClick={handleClickReturn}>
                <ArrowBackIcon style={{ color: "#0274ca" }} />
              </IconButton>
            </Tooltip>
            Save Doctor Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Tooltip title="Clean" style={{ float: "right" }}>
            <IconButton onClick={handleClickCleanDoctorForm}>
              <ClearIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save" style={{ float: "right" }}>
            <IconButton onClick={handleClickSaveDoctor}>
              <SaveIcon style={{ color: "#000000" }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="firstName"
            error={infoDoctor.firstName.trim() === "" && validateForm}
            helperText={
              infoDoctor.firstName.trim() === "" && validateForm ? "This field is require" : ""
            }
            className={classes.textField}
            type="text"
            label="First Name"
            required
            value={infoDoctor.firstName}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 1);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="lastName"
            error={infoDoctor.lastName.trim() === "" && validateForm}
            helperText={
              infoDoctor.lastName.trim() === "" && validateForm ? "This field is require" : ""
            }
            className={classes.textField}
            type="text"
            label="Last Name"
            required
            value={infoDoctor.lastName}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 1);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="address"
            className={classes.textField}
            type="text"
            label="Address"
            value={infoDoctor.address}
            inputProps={{
              maxLength: 100,
            }}
            onKeyPress={(e) => {
              keyValidation(e, 3);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="phone"
            className={classes.textField}
            type="text"
            label="Phone"
            inputProps={{
              maxLength: 15,
            }}
            value={infoDoctor.phone}
            onKeyPress={(e) => {
              keyValidation(e, 2);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="email"
            className={classes.textField}
            type="text"
            label="Email"
            inputProps={{
              maxLength: 100,
            }}
            value={infoDoctor.email}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="startWorkDay"
            className={classes.textField}
            type="time"
            label="Start Work Day"
            disabled={infoDoctor.fullDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            value={infoDoctor.startWorkDay}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="endWorkDay"
            className={classes.textField}
            type="time"
            label="End Work Day"
            disabled={infoDoctor.fullDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            value={infoDoctor.endWorkDay}
            onKeyPress={(e) => {
              keyValidation(e, 4);
            }}
            onChange={handleChangeInputsSaveDoctorInformation}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                id="fullDay"
                checked={infoDoctor.fullDay}
                onChange={handleChangeInputsSaveDoctorInformation}
                name="checkeFT"
                color="primary"
              />
            }
            label="Full Time?"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

function MedicalHistory(props) {
  const setShowSection = props.setShowSection;
  const setIdDoctor = props.setIdDoctor;

  const handleClickReturn = () => {
    setShowSection(0);
    setIdDoctor(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          Medical History
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Doctors;
