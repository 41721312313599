import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  Container,
  Button,
  Badge,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  PermContactCalendar as PermContactCalendarIcon,
  FileCopy as FileCopyIcon,
  Settings as SettingsIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import { SERVER } from "../../config";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import {
  keyValidation,
  pasteValidation,
  validarCorreo,
} from "../../helpers/inputHelpers";
import moment from "moment";
import Logo from "../../assets/images/Logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textField: {
    width: "100%",
  },
  media: {
    height: 140,
  },
  hoverTypograpies: {
    "&:hover": {
      color: "blue",
    },
  },
  badgeEmergencyContactsSuccess: {
    "& span": {
      backgroundColor: "#00b050",
    },
  },
  badgeEmergencyContactsError: {
    "& span": {
      backgroundColor: "#f50057",
    },
  },
}));

export default function Register() {
  const theme = useTheme();
  const classes = useStyles();
  const xsSize = useMediaQuery(theme.breakpoints.only("xs"));

  const [infoPatient, setInfoPatient] = useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    phone: "",
    phone2: "",
    email: "",
    dateOfBirth: "",
    bloodType: "",
    gender: "",
    medicines: "",
    chronicMedicalConditions: "",
    medicalEquipment: "",
    allergies: "",
    immunizationHistory: "",
    mainDoctor: 0,
    mainHospital: 0,
    picture: null,
  });
  const [emergencyContacts, setEmergencyContacts] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });
  const [infoEmergencyContacts, setInfoEmergencyContacts] = useState({
    firstNames: [],
    lastNames: [],
    phones: [],
    emails: [],
  });
  const [documents, setDocuments] = useState({
    file: null,
    description: "",
  });
  const [infoDocuments, setInfoDocument] = useState({
    files: [],
    descriptions: [],
  });
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [validateForm, setValidateForm] = useState(false);
  const [validateFormEmergencyContacts, setValidateFormEmergencyContacts] =
    useState(false);
  const [validateFormFiles, setValidateFormFiles] = useState(false);
  const [openEmergencyContactsDialog, setOpenEmergencyContactsDialog] =
    useState(false);
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const imagesExtensions = ["jpeg", "jpg", "png", "svg", "heic"];
  const filesExtensions = [
    "jpeg",
    "jpg",
    "png",
    "svg",
    "heic",
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "csv",
    "ods",
    "txt",
  ];
  const maximunSize = 5242880; //5 MB in Bytes (in binary)

  const [
    {
      data: registerPatientsData,
      loading: registerPatientsLoading,
      error: registerPatientsError,
    },
    executeRegisterPatient,
  ] = useAxios(
    {
      url: `${SERVER}/patients/register`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    {
      data: getActivesDoctorsData,
      loading: getActivesDoctorsLoading,
      error: getActivesDoctorsError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/doctors/actives`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  const [
    {
      data: getActivesHospitalsData,
      loading: getActivesHospitalsLoading,
      error: getActivesHospitalsError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/hospitals/actives`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (registerPatientsData) {
      if (!registerPatientsData.error) {
        setInfoPatient({
          id: 0,
          firstName: "",
          lastName: "",
          address: "",
          address2: "",
          phone: "",
          phone2: "",
          email: "",
          dateOfBirth: "",
          bloodType: "",
          gender: "",
          medicines: "",
          chronicMedicalConditions: "",
          medicalEquipment: "",
          allergies: "",
          immunizationHistory: "",
          mainDoctor: 0,
          mainHospital: 0,
          picture: null,
        });
        setEmergencyContacts({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
        });
        setInfoEmergencyContacts({
          firstNames: [],
          lastNames: [],
          phones: [],
          emails: [],
        });
        setDocuments({
          file: null,
          description: "",
        });
        setInfoDocument({
          files: [],
          descriptions: [],
        });
        setValidateForm(false);
        Swal.fire({
          icon: "success",
          title: registerPatientsData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setValidateForm(false);
        Swal.fire({
          icon: "error",
          title: registerPatientsData.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }, [registerPatientsData]);

  useEffect(() => {
    if (getActivesDoctorsData) {
      setDoctors(getActivesDoctorsData);
    }
  }, [getActivesDoctorsData]);

  useEffect(() => {
    if (getActivesHospitalsData) {
      setHospitals(getActivesHospitalsData);
    }
  }, [getActivesHospitalsData]);

  if (
    getActivesDoctorsLoading ||
    getActivesHospitalsLoading ||
    registerPatientsLoading
  ) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          textAlign: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="subtitle1">Wait a moment please</Typography>
      </div>
    );
  }

  if (
    getActivesDoctorsError ||
    getActivesHospitalsError ||
    registerPatientsError
  ) {
    Swal.fire({
      icon: "error",
      title: "An error has occurred",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  const handleChangeInputsSavePatient = (e) => {
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      pasteValidation(e, 1);
    } else if (
      e.target.id === "address" ||
      e.target.id === "address2" ||
      e.target.id === "medicines" ||
      e.target.id === "chronicMedicalConditions" ||
      e.target.id === "medicalEquipment" ||
      e.target.id === "allergies" ||
      e.target.id === "immunizationHistory"
    ) {
      pasteValidation(e, 3);
    } else if (e.target.id === "phone" || e.target.id === "phone2") {
      pasteValidation(e, 2);
    } else if (e.target.id === "email") {
      pasteValidation(e, 4);
    }

    setInfoPatient({
      ...infoPatient,
      [e.target.id]: e.target.value,
    });
  };

  const handleClickSavePatient = () => {
    const {
      firstName,
      lastName,
      address,
      address2,
      phone,
      phone2,
      email,
      dateOfBirth,
      bloodType,
      gender,
      medicines,
      chronicMedicalConditions,
      medicalEquipment,
      allergies,
      immunizationHistory,
      mainDoctor,
      mainHospital,
      picture,
    } = infoPatient;
    const { firstNames, lastNames, phones, emails } = infoEmergencyContacts;
    const { files, descriptions } = infoDocuments;
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      phone.trim() === "" ||
      email.trim() === "" ||
      !validarCorreo(email.trim()) ||
      mainDoctor === 0 ||
      mainDoctor === "0" ||
      mainHospital === 0 ||
      mainHospital === "0"
    ) {
      Swal.fire({
        icon: "warning",
        title: "Fill in all the required fields",
        showConfirmButton: false,
        timer: 3000,
      });
      setValidateForm(true);
    } else if (firstNames.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Add at least 1 emergency contact",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      setValidateForm(false);
      const formData = new FormData();
      formData.append("firstName", firstName.trim());
      formData.append("lastName", lastName.trim());
      formData.append("address", address.trim());
      formData.append("address2", address2.trim());
      formData.append("phone", phone.trim());
      formData.append("phone2", phone2.trim());
      formData.append("email", email.trim());
      formData.append("dateOfBirth", dateOfBirth);
      formData.append("bloodType", bloodType);
      formData.append("gender", gender);
      formData.append("medicines", medicines.trim());
      formData.append(
        "chronicMedicalConditions",
        chronicMedicalConditions.trim()
      );
      formData.append("medicalEquipment", medicalEquipment.trim());
      formData.append("allergies", allergies.trim());
      formData.append("immunizationHistory", immunizationHistory.trim());
      formData.append("mainDoctor", parseInt(mainDoctor));
      formData.append("mainHospital", parseInt(mainHospital));
      formData.append("picture", picture);
      formData.append("code", moment().format("YYYYMMDDHmmssSS"));
      formData.append("firstNamesEmergencyContacts", firstNames);
      formData.append("lastNamesEmergencyContacts", lastNames);
      formData.append("phonesEmergencyContacts", phones);
      formData.append("emailsEmergencyContacts", emails);
      for (let x = 0; x < files.length; x++) {
        formData.append("doc" + (x + 1), files[x]);
      }
      formData.append("descriptions", descriptions);
      executeRegisterPatient({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  };

  const addPatientPicture = (e) => {
    if (e.target.files.length > 0) {
      let extension = e.target.files[0].name.split(".");
      extension = extension[extension.length - 1].toLowerCase();
      const imageValidation = imagesExtensions.indexOf(extension);
      if (imageValidation === -1) {
        Swal.fire({
          icon: "error",
          title: "Invalid extension",
          text: "The extensions allowed are: jpeg, jpg, png ,svg and heic.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else if (e.target.files[0].size > maximunSize) {
        Swal.fire({
          icon: "error",
          title: "Very heavy picture",
          text: "The maximum size allowed is 5 MB.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else {
        setInfoPatient({
          ...infoPatient,
          picture: e.target.files[0],
        });
      }
    } else {
      setInfoPatient({
        ...infoPatient,
        picture: null,
      });
    }

    /* let input = document.createElement("input");
    document.body.appendChild(input);
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      let extension = e.target.files[0].name.split(".");
      extension = extension[extension.length - 1].toLowerCase();
      const imageValidation = imagesExtensions.indexOf(extension);
      if (imageValidation === -1) {
        Swal.fire({
          icon: "error",
          title: "Invalid extension",
          text: "The extensions allowed are: jpeg, jpg, png and svg.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else if (e.target.files[0].size > maximunSize) {
        Swal.fire({
          icon: "error",
          title: "Very heavy picture",
          text: "The maximum size allowed is 5 MB.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else {
        setInfoPatient({
          ...infoPatient,
          picture: e.target.files[0],
        });
      }
    };
    input.click();
    document.body.removeChild(input); */
  };

  const addPatientFile = (e) => {
    if (e.target.files.length > 0) {
      let extension = e.target.files[0].name.split(".");
      extension = extension[extension.length - 1].toLowerCase();
      const fileValidation = filesExtensions.indexOf(extension);
      if (fileValidation === -1) {
        Swal.fire({
          icon: "error",
          title: "Invalid extension",
          text: "The extensions allowed are: jpeg, jpg, png, svg, heic, pdf, doc, docx, xls, xlsx, csv, ods and txt.",
          showConfirmButton: false,
          timer: 10000,
          target: document.getElementById("filesDialog"),
        });
      } else if (e.target.files[0].size > maximunSize) {
        Swal.fire({
          icon: "error",
          title: "Very heavy picture",
          text: "The maximum size allowed is 5 MB.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else {
        setDocuments({
          ...documents,
          file: e.target.files[0],
        });
      }
    } else {
      setDocuments({
        ...documents,
        file: null,
      });
    }
    /* let input = document.createElement("input");
    document.body.appendChild(input);
    input.type = "file";
    input.accept =
      "image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.xls,.xlsx,.csv,.ods";
    input.onchange = (e) => {
      let extension = e.target.files[0].name.split(".");
      extension = extension[extension.length - 1].toLowerCase();
      const fileValidation = filesExtensions.indexOf(extension);
      if (fileValidation === -1) {
        Swal.fire({
          icon: "error",
          title: "Invalid extension",
          text: "The extensions allowed are: jpeg, jpg, png, svg, pdf, doc, docx, xls, xlsx, csv, ods and txt.",
          showConfirmButton: false,
          timer: 10000,
          target: document.getElementById("filesDialog"),
        });
      } else if (e.target.files[0].size > maximunSize) {
        Swal.fire({
          icon: "error",
          title: "Very heavy picture",
          text: "The maximum size allowed is 5 MB.",
          showConfirmButton: false,
          timer: 7000,
        });
      } else {
        setDocuments({
          ...documents,
          file: e.target.files[0],
        });
      }
    };
    input.click();
    document.body.removeChild(input); */
  };

  const handleClickOpenEmergencyContactsDialog = () => {
    setOpenEmergencyContactsDialog(true);
  };

  const handleCloseEmergencyContactsDialog = () => {
    setOpenEmergencyContactsDialog(false);
  };

  const handleClickOpenFilesDialog = () => {
    setOpenFilesDialog(true);
  };

  const handleCloseFilesDialog = () => {
    setOpenFilesDialog(false);
  };

  const addEmergencyContact = () => {
    const { firstName, lastName, phone, email } = emergencyContacts;
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      phone.trim() === ""
    ) {
      setValidateFormEmergencyContacts(true);
    } else {
      let newFirstNames = infoEmergencyContacts.firstNames;
      let newLastNames = infoEmergencyContacts.lastNames;
      let newPhones = infoEmergencyContacts.phones;
      let newEmails = infoEmergencyContacts.emails;
      newFirstNames.unshift(firstName.trim());
      newLastNames.unshift(lastName.trim());
      newPhones.unshift(phone.trim());
      newEmails.unshift(email.trim());
      setInfoEmergencyContacts({
        firstNames: newFirstNames,
        lastNames: newLastNames,
        phones: newPhones,
        emails: newEmails,
      });
      setEmergencyContacts({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      });
      setValidateFormEmergencyContacts(false);
    }
  };

  const removeEmergencyContact = (index) => {
    Swal.fire({
      title: "Are you sure to remove the emergency contact?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
      target: document.getElementById("emergencyContactsDialog"),
    }).then((result) => {
      if (result.isConfirmed) {
        let newFirstNames = infoEmergencyContacts.firstNames;
        let newLastNames = infoEmergencyContacts.lastNames;
        let newPhones = infoEmergencyContacts.phones;
        let newEmails = infoEmergencyContacts.emails;
        newFirstNames.splice(index, 1);
        newLastNames.splice(index, 1);
        newPhones.splice(index, 1);
        newEmails.splice(index, 1);
        setInfoEmergencyContacts({
          firstNames: newFirstNames,
          lastNames: newLastNames,
          phones: newPhones,
          emails: newEmails,
        });
      }
    });
  };

  const addFile = () => {
    const { file, description } = documents;
    if (file === null || description.trim() === "") {
      setValidateFormFiles(true);
    } else {
      let newFiles = infoDocuments.files;
      let newDescriptions = infoDocuments.descriptions;
      newFiles.unshift(file);
      newDescriptions.unshift(description.trim());
      setInfoDocument({
        files: newFiles,
        descriptions: newDescriptions,
      });
      setDocuments({
        file: null,
        description: "",
      });
      setValidateFormFiles(false);
    }
  };

  const removeFile = (index) => {
    Swal.fire({
      title: "Are you sure to remove the file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#0274ca",
      confirmButtonText: "Yes",
      target: document.getElementById("filesDialog"),
    }).then((result) => {
      if (result.isConfirmed) {
        let newFiles = infoDocuments.files;
        let newDescriptions = infoDocuments.descriptions;
        newFiles.splice(index, 1);
        newDescriptions.splice(index, 1);
        setInfoDocument({
          files: newFiles,
          descriptions: newDescriptions,
        });
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: 15, margin: 30 }}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={Logo} alt="logo" height={xsSize ? 100 : 200} />
            <Typography variant="h6" align="center">
              Registration Form
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ textAlign: "center", alignSelf: "center" }}
          >
            <input type="file" accept="image/*" style={{maxWidth: "100%"}} onChange={addPatientPicture} />
            <Typography
              variant="subtitle2"
              style={{ fontSize: "16px", marginTop: "15px" }}
            >
              Picture
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.hoverTypograpies}
              style={{ display: "inline-grid", cursor: "pointer" }}
              onClick={addPatientPicture}
            >
              <>
                <ImageIcon
                  fontSize="large"
                  color="primary"
                  style={{ justifySelf: "center" }}
                />
                <span>
                  {infoPatient.picture
                    ? infoPatient.picture.name
                    : "Add a picture"}
                </span>
              </>
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.hoverTypograpies}
              style={{
                display: "inline-grid",
                cursor: "pointer",
                justifyItems: "center",
              }}
              onClick={handleClickOpenEmergencyContactsDialog}
            >
              <Badge
                badgeContent={infoEmergencyContacts.firstNames.length.toString()}
                color="secondary"
                className={
                  infoEmergencyContacts.firstNames.length !== 0
                    ? classes.badgeEmergencyContactsSuccess
                    : classes.badgeEmergencyContactsError
                }
              >
                <PermContactCalendarIcon
                  fontSize="large"
                  color="primary"
                  style={{ justifySelf: "center" }}
                />
              </Badge>
              <span>Emergency Contacts</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.hoverTypograpies}
              style={{ display: "inline-grid", cursor: "pointer" }}
              onClick={handleClickOpenFilesDialog}
            >
              <Badge
                badgeContent={infoDocuments.files.length.toString()}
                color="secondary"
                className={classes.badgeEmergencyContactsSuccess}
              >
                <FileCopyIcon
                  fontSize="large"
                  color="primary"
                  style={{ justifySelf: "center" }}
                />
              </Badge>
              <span>Files</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="firstName"
              error={infoPatient.firstName.trim() === "" && validateForm}
              helperText={
                infoPatient.firstName.trim() === "" && validateForm
                  ? "This field is require"
                  : ""
              }
              className={classes.textField}
              type="text"
              label="First Name"
              required
              value={infoPatient.firstName}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 1);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="lastName"
              error={infoPatient.lastName.trim() === "" && validateForm}
              helperText={
                infoPatient.lastName.trim() === "" && validateForm
                  ? "This field is require"
                  : ""
              }
              className={classes.textField}
              type="text"
              label="Last Name"
              required
              value={infoPatient.lastName}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 1);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="address"
              className={classes.textField}
              type="text"
              label="Address 1"
              value={infoPatient.address}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="address2"
              className={classes.textField}
              type="text"
              label="Address 2"
              value={infoPatient.address2}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="phone"
              error={infoPatient.phone.trim() === "" && validateForm}
              helperText={
                infoPatient.phone.trim() === "" && validateForm
                  ? "This field is require"
                  : ""
              }
              className={classes.textField}
              type="text"
              label="Phone"
              required
              inputProps={{
                maxLength: 15,
              }}
              value={infoPatient.phone}
              onKeyPress={(e) => {
                keyValidation(e, 2);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="phone2"
              className={classes.textField}
              type="text"
              label="Phone 2"
              inputProps={{
                maxLength: 15,
              }}
              value={infoPatient.phone2}
              onKeyPress={(e) => {
                keyValidation(e, 2);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="email"
              error={
                (infoPatient.email.trim() === "" ||
                  !validarCorreo(infoPatient.email.trim())) &&
                validateForm
              }
              helperText={
                infoPatient.email.trim() === "" && validateForm
                  ? "This field is require"
                  : !validarCorreo(infoPatient.email.trim()) && validateForm
                  ? "Enter a valid email"
                  : ""
              }
              className={classes.textField}
              type="text"
              label="Email"
              required
              inputProps={{
                maxLength: 100,
              }}
              value={infoPatient.email}
              onKeyPress={(e) => {
                keyValidation(e, 4);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="dateOfBirth"
              className={classes.textField}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Date Of Birth"
              value={infoPatient.dateOfBirth}
              onKeyPress={(e) => {
                keyValidation(e, 4);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="bloodType"
              className={classes.textField}
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Blood Type"
              value={infoPatient.bloodType}
              onChange={handleChangeInputsSavePatient}
            >
              <option value="">Select a blood type</option>
              <option value="A+">A+</option>
              <option value="B+">B+</option>
              <option value="A-">A-</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="gender"
              className={classes.textField}
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Gender"
              value={infoPatient.gender}
              onChange={handleChangeInputsSavePatient}
            >
              <option value="">Select a gender</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="mainDoctor"
              error={
                (infoPatient.mainDoctor === 0 ||
                  infoPatient.mainDoctor === "0") &&
                validateForm
              }
              helperText={
                (infoPatient.mainDoctor === 0 ||
                  infoPatient.mainDoctor === "0") &&
                validateForm
                  ? "This field is require"
                  : ""
              }
              className={classes.textField}
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Main Doctor"
              required
              value={infoPatient.mainDoctor}
              onChange={handleChangeInputsSavePatient}
            >
              <option value={0}>Select a Main Doctor</option>
              {doctors.map((doctor, index) => (
                <option
                  key={index}
                  value={doctor.id}
                >{`${doctor.firstName} ${doctor.lastName} (${doctor.phone})`}</option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="mainHospital"
              error={
                (infoPatient.mainHospital === 0 ||
                  infoPatient.mainHospital === "0") &&
                validateForm
              }
              helperText={
                (infoPatient.mainHospital === 0 ||
                  infoPatient.mainHospital === "0") &&
                validateForm
                  ? "This field is require"
                  : ""
              }
              className={classes.textField}
              select
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Main Hospital"
              required
              value={infoPatient.mainHospital}
              onChange={handleChangeInputsSavePatient}
            >
              <option value={0}>Select a Main Hospital</option>
              {hospitals.map((hospital, index) => (
                <option
                  key={index}
                  value={hospital.id}
                >{`${hospital.name} (${hospital.phone})`}</option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="medicines"
              className={classes.textField}
              type="text"
              label="Medicines"
              variant="outlined"
              multiline
              rows={4}
              value={infoPatient.medicines}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="chronicMedicalConditions"
              className={classes.textField}
              type="text"
              label="Chronic Medical Conditions"
              variant="outlined"
              multiline
              rows={4}
              value={infoPatient.chronicMedicalConditions}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="medicalEquipment"
              className={classes.textField}
              type="text"
              label="Medical Equipment"
              variant="outlined"
              multiline
              rows={4}
              value={infoPatient.medicalEquipment}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="allergies"
              className={classes.textField}
              type="text"
              label="Allergies"
              variant="outlined"
              multiline
              rows={4}
              value={infoPatient.allergies}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="immunizationHistory"
              className={classes.textField}
              type="text"
              label="Immunization History"
              variant="outlined"
              multiline
              rows={4}
              value={infoPatient.immunizationHistory}
              onKeyPress={(e) => {
                keyValidation(e, 3);
              }}
              onChange={handleChangeInputsSavePatient}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSavePatient}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        id="emergencyContactsDialog"
        fullScreen={xsSize ? true : false}
        maxWidth="lg"
        fullWidth
        open={openEmergencyContactsDialog}
        onClose={handleCloseEmergencyContactsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Emergency Contacts</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                id={"firstNameEmergencyContacts"}
                error={
                  emergencyContacts.firstName.trim() === "" &&
                  validateFormEmergencyContacts
                }
                helperText={
                  emergencyContacts.firstName.trim() === "" &&
                  validateFormEmergencyContacts
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="First Name"
                required
                value={emergencyContacts.firstName}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 1);
                }}
                onChange={(e) => {
                  pasteValidation(e, 1);
                  setEmergencyContacts({
                    ...emergencyContacts,
                    firstName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id={"lastNameEmergencyContacts"}
                error={
                  emergencyContacts.lastName.trim() === "" &&
                  validateFormEmergencyContacts
                }
                helperText={
                  emergencyContacts.lastName.trim() === "" &&
                  validateFormEmergencyContacts
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="Last Name"
                required
                value={emergencyContacts.lastName}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 1);
                }}
                onChange={(e) => {
                  pasteValidation(e, 1);
                  setEmergencyContacts({
                    ...emergencyContacts,
                    lastName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id={"phoneEmergencyContacts"}
                error={
                  emergencyContacts.phone.trim() === "" &&
                  validateFormEmergencyContacts
                }
                helperText={
                  emergencyContacts.phone.trim() === "" &&
                  validateFormEmergencyContacts
                    ? "This field is require"
                    : ""
                }
                className={classes.textField}
                type="text"
                label="Phone"
                inputProps={{
                  maxLength: 15,
                }}
                required
                value={emergencyContacts.phone}
                onKeyPress={(e) => {
                  keyValidation(e, 2);
                }}
                onChange={(e) => {
                  pasteValidation(e, 2);
                  setEmergencyContacts({
                    ...emergencyContacts,
                    phone: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id={"emailEmergencyContacts"}
                className={classes.textField}
                type="text"
                label="Email"
                inputProps={{
                  maxLength: 100,
                }}
                value={emergencyContacts.email}
                onKeyPress={(e) => {
                  keyValidation(e, 4);
                }}
                onChange={(e) => {
                  pasteValidation(e, 4);
                  setEmergencyContacts({
                    ...emergencyContacts,
                    email: e.target.value,
                  });
                }}
              />
            </Grid>
            {infoEmergencyContacts.firstNames.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Emergency contacts saved
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <List>
                {infoEmergencyContacts.firstNames.map((firstName, index) => (
                  <Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => removeEmergencyContact(index)}
                    >
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid item xs={12} md={3}>
                              <Typography variant="subtitle1">
                                {firstName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography variant="subtitle1">
                                {infoEmergencyContacts.lastNames[index]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography variant="subtitle1">
                                {infoEmergencyContacts.phones[index]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography variant="subtitle1">
                                {infoEmergencyContacts.emails[index]}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {infoEmergencyContacts.firstNames.length > 0 && (
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ position: "absolute", left: xsSize ? "5%" : "2%" }}
            >
              *Click to remove a contact
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseEmergencyContactsDialog}
          >
            Exit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addEmergencyContact}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="filesDialog"
        fullScreen={xsSize ? true : false}
        maxWidth="md"
        fullWidth
        open={openFilesDialog}
        onClose={handleCloseFilesDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Files</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id={"descriptionDocument"}
                label="Description"
                fullWidth
                error={documents.description.trim() === "" && validateFormFiles}
                helperText={
                  documents.description.trim() === "" && validateFormFiles
                    ? "This field is require"
                    : ""
                }
                required
                value={documents.description}
                inputProps={{
                  maxLength: 100,
                }}
                onKeyPress={(e) => {
                  keyValidation(e, 3);
                }}
                onChange={(e) => {
                  pasteValidation(e, 3);
                  setDocuments({
                    ...documents,
                    description: e.target.value,
                  });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.xls,.xlsx,.csv,.ods"
                style={{maxWidth: "100%"}}
                onChange={addPatientFile}
              />
              <Typography
                variant="subtitle2"
                style={{ color: "#f44336", fontSize: "12px" }}
              >
                {documents.file === null && validateFormFiles
                  ? "This field is require"
                  : ""}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Card
                style={{
                  border:
                    documents.file === null && validateFormFiles
                      ? "solid red 2px"
                      : "",
                }}
              >
                <CardActionArea
                  style={{ width: "100%", height: "200px", padding: 10 }}
                  onClick={addPatientFile}
                >
                  <Typography variant="h3" align="center">
                    {documents.file
                      ? documents.file.name
                      : "Click to add a file"}
                  </Typography>
                </CardActionArea>
              </Card>
              <Typography
                variant="subtitle2"
                style={{ color: "#f44336", fontFamily: "12px" }}
              >
                {documents.file === null && validateFormFiles
                  ? "This field is require"
                  : ""}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Files uploaded
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ background: "#fafafa" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Description</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>File</strong>
                      </TableCell>
                      <TableCell align="right">
                        <SettingsIcon style={{ color: "#000000" }} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {infoDocuments.files.length > 0 ? (
                      infoDocuments.files.map((file, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {infoDocuments.descriptions[index]}
                          </TableCell>
                          <TableCell align="right">{file.name}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Remove File">
                              <IconButton onClick={() => removeFile(index)}>
                                <CancelIcon color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No files uploaded
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* <Grid item xs={12}>
              <List>
                {infoDocuments.files.map((file, index) => (
                  <Fragment key={index}>
                    <ListItem button onClick={() => removeFile(index)}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              <Typography variant="subtitle1">
                                {infoDocuments.descriptions[index]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="subtitle1">
                                {file.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {infoDocuments.files.length > 0 && (
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ position: "absolute", left: xsSize ? "5%" : "2%" }}
            >
              *Click to remove a file
            </Typography>
          )} */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseFilesDialog}
          >
            Exit
          </Button>
          <Button variant="contained" color="primary" onClick={addFile}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
