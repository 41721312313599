import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import MTMLogo from "../../assets/images/Logo.png";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import { SERVER } from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(3, 2),
  },
  textFields: {
    width: "100%",
  },
  logo: {
    width: "70%",
    padding: "10px",
  },
}));

export default function Login() {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [activeLogin, setActiveLogin] = useState(false);
  const [goToScanner, setGoToScanner] = useState(false);

  const [
    { data: checkLoginData, loading: checkLoginLoading, error: checkLoginError },
    executeCheckLogin,
  ] = useAxios(
    {
      url: `${SERVER}/login/check`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [
    { data: loginData, loading: loginLoading, error: loginError },
    executeLogin,
  ] = useAxios(
    {
      url: `${SERVER}/login`,
      method: "POST",
    },
    {
      useCache: false,
      manual: true,
    }
  );

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      const token = sessionStorage.getItem("token");
      executeCheckLogin({
        data: {
          token: token
        }
      });
    }
    else {
      setActiveLogin(false);
    }
  }, [executeCheckLogin]);

  useEffect(() => {
    if (checkLoginData) {
      setActiveLogin(checkLoginData.validateLogin);
    }
  }, [checkLoginData]);

  useEffect(() => {
    if (loginData) {
      if (loginData.error) {
        Swal.fire({
          icon: "error",
          title: loginData.message,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: loginData.message,
          showConfirmButton: true,
        });
        sessionStorage.setItem("token", loginData.token);
        setActiveLogin(true);
      }
    }
  }, [loginData]);

  if (loginLoading || checkLoginLoading) {
    return <CircularProgress />;
  }

  if (loginError || checkLoginError) {
    Swal.fire({
      icon: "error",
      title: "An error occurred on the server",
      showConfirmButton: true,
    });
  }

  const login = async () => {
    if (user.trim() === "" || password.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Put an user and a password",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      executeLogin({
        data: {
          user: user.trim(),
          password: password.trim(),
        },
      });
    }
  };

  const onGoToScanner = () => {
    setGoToScanner(true);
  }

  return activeLogin ? (<Redirect to="/" />)
    : goToScanner ? (<Redirect to="/codeReader" />)
      : (
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={10} sm={6} md={3}>
            <Card className={classes.root}>
              <Grid container style={{ textAlign: 'end' }}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={onGoToScanner}
                  >
                    Scanner
                  </Button>
                </Grid>
              </Grid>
              <center>
                <img src={MTMLogo} alt="MTM logo" className={classes.logo} />
              </center>
              <CardContent>
                <TextField
                  className={classes.textFields}
                  required
                  id="user"
                  label="User"
                  margin="normal"
                  value={user}
                  inputProps={{
                    maxLength: 20,
                  }}
                  onChange={(e) => {
                    setUser(e.target.value);
                  }}
                />
                <TextField
                  className={classes.textFields}
                  required
                  id="password"
                  label="Password"
                  margin="normal"
                  value={password}
                  inputProps={{
                    maxLength: 20,
                    type: "password",
                  }}
                  onKeyPress={(e) => {
                    const pressKey = e.keyCode ? e.keyCode : e.which;
                    if (pressKey === 13) {
                      /* login(); */
                    }
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </CardContent>
              <CardActions>
                <Grid container style={{ textAlign: 'center' }}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      /* disabled={disabledButton} */
                      onClick={login}
                    >
                      LOG IN
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      );
}
