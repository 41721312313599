import React, { useState } from 'react';
import Logo from "../../assets/images/Logo.png";
import { Button, CircularProgress, Grid, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import { keyValidation, pasteValidation } from '../../helpers/inputHelpers';
import Swal from 'sweetalert2';
import useAxios from 'axios-hooks';
import { useEffect } from 'react';
import { SERVER } from '../../config';
import moment from 'moment';
import PDFPeopleInformation from '../componentsHelpers/PDFPeopleInformation';
import { pdf } from '@react-pdf/renderer';

export default function PeopleInformation() {
    const [secureCode, setSecureCode] = useState("");
    const [information, setInformation] = useState([]);
    const [emergencyContacts, setEmergencyContacts] = useState([]);
    const [showView, setShowView] = useState(0);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [
        {
            data: validateCodeData,
            loading: validateCodeLoading,
            error: validateCodeError,
        },
        executeValidateCode,
    ] = useAxios(
        {
            url: `${SERVER}/peopleInformation/validateCode/`,
            method: "POST",
        },
        {
            useCache: false,
            manual: true,
        }
    );

    const [
        {
            data: peopleEmergencyData,
            loading: peopleEmergencyLoading,
            error: peopleEmergencyError,
        },
        executePeopleEmergency,
    ] = useAxios(
        {
            url: `${SERVER}/peopleInformation/peopleEmergency/`,
            method: "POST",
        },
        {
            useCache: false,
            manual: true,
        }
    );

    useEffect(() => {
        try {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            }, (error) => {
                // El segundo parámetro es la función de error
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.log('The user denied permission for Geolocation.');
                        // El usuario denegó el permiso para la Geolocalización.
                        Swal.fire({
                            icon: "warning",
                            title: "You must first give location permissions to your device for alerts to work properly.",
                            showConfirmButton: false,
                        });
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log('The location is not available.');
                        // La ubicación no está disponible.
                        break;
                    case error.TIMEOUT:
                        console.log('The time to get the location has been exceeded.');
                        // Se ha excedido el tiempo para obtener la ubicación.
                        break;
                    case error.UNKNOWN_ERROR:
                        console.log('An unknown bug.');
                        // Un error desconocido.
                        break;
                    default:
                        console.log('An unknown bug (default).');
                        // Un error desconocido.
                        break;
                }
            }
            );
        } catch (err) {
            Swal.fire({
                icon: "error",
                title: err,
                showConfirmButton: true,
            });
            setShowView(0);
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (!validateCodeData) {
            return;
        }

        if (validateCodeData.error) {
            Swal.fire({
                icon: "error",
                title: validateCodeData.message,
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }
        setInformation(validateCodeData.information);
        setEmergencyContacts(validateCodeData.emergencyContacts);
        setShowView(1);

    }, [validateCodeData]);

    useEffect(() => {
        if (!peopleEmergencyData) {
            return;
        }

        if (peopleEmergencyData.error) {
            Swal.fire({
                icon: "warning",
                title: peopleEmergencyData.message,
                showConfirmButton: true,
                timer: 3000,
            });
            return;
        }

        Swal.fire({
            icon: "success",
            title: peopleEmergencyData.message,
            showConfirmButton: true,
            timer: 3000,
        });

    }, [peopleEmergencyData]);

    if (validateCodeLoading || peopleEmergencyLoading) {
        return <CircularProgress />;
    }

    if (validateCodeError || peopleEmergencyError) {
        Swal.fire({
            icon: "error",
            title: "An error occurred on the server",
            showConfirmButton: true,
        });
    }

    const handleChangueSegureCode = (e) => {
        pasteValidation(e, 2);
        setSecureCode(e.target.value);
    };

    const handleClickSendSegureCode = () => {
        if (secureCode.trim() === "") {
            Swal.fire({
                icon: "warning",
                title: "Enter a security code please",
                showConfirmButton: false,
                timer: 3000,
            });
        } else {
            executeValidateCode({
                data: {
                    secureCode: secureCode
                }
            });
        }
    };

    const handleClickExit = () => {
        Swal.fire({
            title: "Are you sure to exit?",
            text: "The session will be delete.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f50057",
            cancelButtonColor: "#0274ca",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                setShowView(0);
            }
        });
    }

    const handleClickEmergency = () => {

        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            Swal.fire({
                title: "Are you sure to make an emergency call?",
                text: "An email and SMS with your location will be sent to the MTM staff.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f50057",
                cancelButtonColor: "#0274ca",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const blobPDF = await pdf(<PDFPeopleInformation information={information} emergencyContacts={emergencyContacts} />).toBlob();
                    const pdfName = `(${information.id})${information.firstName} ${information.lastName} info.pdf`;

                    const formData = new FormData();
                    formData.append("firstName", information.firstName);
                    formData.append("lastName", information.lastName);
                    formData.append("latitude", latitude);
                    formData.append("longitude", longitude);
                    formData.append("namePDF", pdfName);
                    formData.append("pathPDF", blobPDF);
                    executePeopleEmergency({
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                }
            });
        }, (error) => {
            let message = '';
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    console.log('The user denied permission for Geolocation.');
                    message = 'You must first give location permissions to your device for alerts to work properly.';
                    // El usuario denegó el permiso para la Geolocalización.
                    break;
                case error.POSITION_UNAVAILABLE:
                    console.log('The location is not available.');
                    message = 'The location is not available.';
                    // La ubicación no está disponible.
                    break;
                case error.TIMEOUT:
                    console.log('The time to get the location has been exceeded.');
                    message = 'The time to get the location has been exceeded.';
                    // Se ha excedido el tiempo para obtener la ubicación.
                    break;
                case error.UNKNOWN_ERROR:
                    console.log('An unknown bug.');
                    message = 'The location is not available.';
                    // Un error desconocido.
                    break;
                default:
                    console.log('An unknown bug (default).');
                    message = 'The location is not available.';
                    // Un error desconocido.
                    break;
            }
            Swal.fire({
                icon: "warning",
                title: message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
        );
    };

    return (
        showView === 0 ? (
            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img src={Logo} alt="logo" height={200} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center">
                        Enter your security code
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <TextField
                        id="secureCode"
                        type="text"
                        value={secureCode}
                        inputProps={{
                            maxLength: 5,
                        }}
                        onKeyPress={(e) => {
                            keyValidation(e, 2);
                        }}
                        onChange={handleChangueSegureCode}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickSendSegureCode}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>
        ) : (
            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} style={{ textAlign: "end" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClickExit}
                    >
                        Exit
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img src={Logo} alt="logo" height={200} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color={"secondary"}
                        onClick={handleClickEmergency}
                    >
                        EMERGENCY
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="First Name"
                                secondary={information.firstName}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Last Name"
                                secondary={information.lastName}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Phone"
                                secondary={information.phone}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Hotel"
                                secondary={information.hotel}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Room Number"
                                secondary={information.roomNumber}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Start Date"
                                secondary={moment(information.startDate).format('DD/MM/YYYY')}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="End Date"
                                secondary={moment(information.endDate).format('DD/MM/YYYY')}
                            />
                        </ListItem>
                        {emergencyContacts?.map((contact, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`Emergency Contact ${index + 1}`}
                                    secondary={`${contact.firstName} ${contact.firstName} (${contact.phone})`}
                                />
                            </ListItem>
                        )
                        )}
                    </List>
                </Grid>
            </Grid>
        )
    )
}
