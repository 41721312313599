import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import useAxios from "axios-hooks";
import Swal from "sweetalert2";
import { SERVER } from "../../config";
import moment from "moment";

export default function Payments() {
  const [payments, setPayments] = useState([]);
  const [showSection, setShowSection] = useState(0);
  const [dateSelected, setDateSelected] = useState("");
  const calendarComponentRef = useRef(null);
  const [
    {
      data: getPaymentsData,
      loading: getPaymentsLoading,
      error: getPaymentsError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/Payments/`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (getPaymentsData) {
      let paymentsData = getPaymentsData;
      for (let x = 0; x < paymentsData.length; x++) {
        paymentsData[x].date = moment(paymentsData[x].date).format(
          "YYYY-MM-DD"
        );
      }
      setPayments(paymentsData);
    }
  }, [getPaymentsData]);

  useEffect(() => {
    if(calendarComponentRef.current && dateSelected) {
      let calendarApi = calendarComponentRef.current.getApi();
      calendarApi.gotoDate(dateSelected);
    }
  }, [showSection, dateSelected]);

  if (getPaymentsLoading) {
    return <CircularProgress />;
  }
  if (getPaymentsError) {
    Swal.fire({
      icon: "error",
      title: "An error has occurred",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  return showSection === 0 ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Home</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Select a day to view all next patient payments.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FullCalendar
            ref={calendarComponentRef}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth",
            }}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            /* selectable
          selectMirror */
            events={payments}
            eventColor="#0274ca"
            dateClick={(info) => {
              const numPayments = payments.filter(
                (visit) => visit.date === info.dateStr
              ).length;
              if (numPayments === 0) {
                Swal.fire({
                  icon: "warning",
                  title: "No next payments on date " + info.dateStr,
                  showConfirmButton: false,
                  timer: 3000,
                });
              } else {
                setShowSection(1);
                setDateSelected(info.dateStr);
              }
            }}
            eventClick={(eventInfo) => {
              const numPayments = payments.filter(
                (visit) => visit.date === eventInfo.event.startStr
              ).length;
              if (numPayments === 0) {
                Swal.fire({
                  icon: "warning",
                  title: "No payments on date " + eventInfo.event.startStr,
                  showConfirmButton: false,
                  timer: 3000,
                });
              } else {
                setShowSection(1);
                setDateSelected(eventInfo.event.startStr);
              }
            }}
          />
        </Grid>
      </Fragment>
    </Grid>
  ) : (
    <PaymentPerDay
      setShowSection={setShowSection}
      dateSelected={dateSelected}
    />
  );
}

function PaymentPerDay(props) {
  const setShowSection = props.setShowSection;
  const dateSelected = props.dateSelected;
  const [payments, setPayments] = useState([]);

  const [
    {
      data: getPaymentsPerDayData,
      loading: getPaymentsPerDayLoading,
      error: getPaymentsPerDayError,
    },
  ] = useAxios(
    {
      url: `${SERVER}/payments/${dateSelected}`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (getPaymentsPerDayData) {
      setPayments(getPaymentsPerDayData);
    }
  }, [getPaymentsPerDayData]);

  if (getPaymentsPerDayLoading) {
    return <CircularProgress />;
  }
  if (getPaymentsPerDayError) {
    Swal.fire({
      icon: "error",
      title: "An error has occurred",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  const handleClickReturn = () => {
    setShowSection(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">
          <Tooltip title="Back">
            <IconButton onClick={handleClickReturn}>
              <ArrowBackIcon style={{ color: "#0274ca" }} />
            </IconButton>
          </Tooltip>
          {`Next payments of the day ${dateSelected}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#fafafa" }}>
              <TableRow>
                <TableCell><strong>Patient</strong></TableCell>
                <TableCell align="right"><strong>Phone</strong></TableCell>
                <TableCell align="right"><strong>Email</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment, index) => (
                <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${payment.firstName} ${payment.lastName}`}
                </TableCell>
                <TableCell align="right">{payment.phone}</TableCell>
                <TableCell align="right">{payment.email}</TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
