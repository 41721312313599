import React from "react";
import { Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import Logo from "../../assets/images/Logo.png";

const PDFStyles = StyleSheet.create({
    pages: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: '150px',
        height: '100px'
    },
    rows: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    columns: {
        width: '70%',
        border: '1px solid black',
        padding: 5,
        marginRight: 5,
        marginLeft: 5,
        marginTop: 30
    },
    titles: {
        fontSize: '8px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    headers: {
        fontSize: '8px',
        fontWeight: 'bold',
    },
    rowTitles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowsInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid black',
        marginBottom: '5px',
    },
});

export default function PDFView(props) {
    const patientData = props.data;
    const patientsPerPages = [];
    const LENGTH_PER_PAGE = 40;
    for (let i = 0; i < patientData.length; i += LENGTH_PER_PAGE) {
        let pedazo = patientData.slice(i, i + LENGTH_PER_PAGE);
        patientsPerPages.push(pedazo);
    }
    const firstPatientPage = patientsPerPages.splice(0, 1);
    return (
        <Document>
            <Page size="A4" style={PDFStyles.pages}>
                <Image src={Logo} style={PDFStyles.logo} />
                <View style={PDFStyles.rows}>
                    <View style={PDFStyles.columns}>
                        <View style={PDFStyles.rowTitles}>
                            <View style={{ width: '50%' }}>
                                <Text style={PDFStyles.titles}>PATIENT´S REPORT</Text>
                            </View>
                            <View style={{ width: '50%', textAlign: 'right' }}>
                                <Text style={PDFStyles.titles}>{`${patientData.length} patients`}</Text>
                            </View>
                        </View>
                        <View style={PDFStyles.rowsInfo}>
                            <View style={{ width: '23%' }}>
                                <Text style={PDFStyles.headers}>PATIENT</Text>
                            </View>
                            <View style={{ width: '18%' }}>
                                <Text style={PDFStyles.headers}>PHONE</Text>
                            </View>
                            <View style={{ width: '31%' }}>
                                <Text style={PDFStyles.headers}>EMAIL</Text>
                            </View>
                            <View style={{ width: '20%' }}>
                                <Text style={PDFStyles.headers}>NEXT PAYMENT</Text>
                            </View>
                        </View>
                        {firstPatientPage.length > 0 && firstPatientPage[0].map((patient, index) => (
                            <View style={PDFStyles.rowsInfo} key={index}>
                                <View style={{ width: '23%' }}>
                                    <Text style={PDFStyles.headers}>{patient.patient}</Text>
                                </View>
                                <View style={{ width: '18%' }}>
                                    <Text style={PDFStyles.headers}>{patient.phone}</Text>
                                </View>
                                <View style={{ width: '31%' }}>
                                    <Text style={PDFStyles.headers}>{patient.email}</Text>
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Text style={PDFStyles.headers}>{patient.nextPayment}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
            {patientsPerPages.length > 0 && patientsPerPages.map((page, indexPage) => (
                <Page size="A4" style={PDFStyles.pages} key={indexPage}>
                    <View style={PDFStyles.rows}>
                        <View style={PDFStyles.columns}>
                            <View style={PDFStyles.rowTitles}>
                                <View style={{ width: '100%' }}>
                                </View>
                            </View>
                            {page.map((patient, index) => (
                                <View style={PDFStyles.rowsInfo} key={index}>
                                    <View style={{ width: '23%' }}>
                                        <Text style={PDFStyles.headers}>{patient.patient}</Text>
                                    </View>
                                    <View style={{ width: '18%' }}>
                                        <Text style={PDFStyles.headers}>{patient.phone}</Text>
                                    </View>
                                    <View style={{ width: '31%' }}>
                                        <Text style={PDFStyles.headers}>{patient.email}</Text>
                                    </View>
                                    <View style={{ width: '20%' }}>
                                        <Text style={PDFStyles.headers}>{patient.nextPayment}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    )
}